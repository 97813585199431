import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Field, touch, change } from 'redux-form'
import { Tooltip } from 'react-tippy'

// atoms
import CheckboxField from '../../../atoms/CheckboxField'

// utils
import { formatAddress } from '../../../utils/address'
import { formatFormValue } from '../../../utils/form'

import { COLUMNS_COUNT, EDIT_MODE } from '../../../containers/GenericUkon/genericUkonConfig'

class AdresaPostovePoukazkyField extends React.Component {
	static propTypes = {
		t: PropTypes.func.isRequired,
		dispatch: PropTypes.func.isRequired,
		editMode: PropTypes.string.isRequired,
		field: PropTypes.string.isRequired,
		value: PropTypes.shape().isRequired,
		originalValue: PropTypes.shape(),
		columnsCount: PropTypes.string,
		formValues: PropTypes.shape().isRequired
	}

	constructor(props) {
		super(props)
	}

	handleChangeCheckbox = (newValue) => {
		const { dispatch, form, field } = this.props

		dispatch(touch(form, field))
		if (newValue === false) {
			dispatch(change(form, field, null))
		}
	}

	formatValue = (value) => {
		if (value === null) {
			return true
		}

		return false
	}

	parseValue = (value) => {
		const { formValues } = this.props

		if (value === true) {
			return null
		}

		return formValues?.data?.adresaKorespondencna?.id ?? null
	}

	render() {
		const { t, field, value, originalValue, columnsCount, editMode, formValues } = this.props

		// CP-3273 - Ak sa označí checkbox, tak sa nastaví adresaOdoslanéPlatby = NULL (Nemeniť adresu poštových poukážok)
		// Ak NIE, pošle sa ID zhodné s adresou korešpondenčnou a používateľovi zobrazíme adresu korešpondenčnú
		let newAddress = null
		if (value) {
			newAddress = formValues?.data?.adresaKorespondencna ?? formValues?.adresaKorespondencna
		}

		return (
			<div className='inner-box'>
				<table className='content-table padded bordered' cellSpacing='0'>
					<tbody>
						{(editMode == EDIT_MODE.EDIT || editMode == EDIT_MODE.LOOKUP || editMode == EDIT_MODE.CREATE) && (
							<tr>
								<td>
									<strong>{t('components:GenericFields.Adresa pre doručovanie poštových poukážok')}</strong>
									<Tooltip
										html={<span>{t('components:GenericFields.Po ukončení dedičského konania postupujte podľa metodiky')}</span>}
										position='bottom'
										trigger='mouseenter'
										theme='light'
									>
										<div className='info-icon' />
									</Tooltip>
								</td>
								{columnsCount === COLUMNS_COUNT.THREE && <td>{formatAddress(originalValue, false) ?? '-'}</td>}
								<td>
									<Field
										name={field}
										onChange={this.handleChangeCheckbox}
										component={CheckboxField}
										label={t('components:GenericFields.Nemeniť adresu poštových poukážok')}
										format={this.formatValue}
										parse={this.parseValue}
									/>
									{newAddress && <div style={{ marginTop: '10px' }}>{formatAddress(newAddress)}</div>}
								</td>
							</tr>
						)}
						{(editMode == EDIT_MODE.CONFIRM || editMode == EDIT_MODE.DETAIL) && (
							<>
								<tr>
									<td>
										<strong>{t('components:GenericFields.Adresa pre doručovanie poštových poukážok')}</strong>
										<Tooltip
											html={<span>{t('components:GenericFields.Po ukončení dedičského konania postupujte podľa metodiky')}</span>}
											position='bottom'
											trigger='mouseenter'
											theme='light'
										>
											<div className='info-icon' />
										</Tooltip>
									</td>
									{columnsCount === COLUMNS_COUNT.THREE && <td>{formatAddress(originalValue, false) ?? '-'}</td>}
									<td>
										{newAddress !== null ? (
											<strong>{formatAddress(newAddress, false)}</strong>
										) : (
											t('components:GenericFields.Nemeniť adresu poštových poukážok')
										)}
									</td>
								</tr>
								{newAddress?.doRukMeno && (
									<tr>
										<td>
											<strong>{t('components:GenericFields.Do rúk')}</strong>
										</td>
										{columnsCount === COLUMNS_COUNT.THREE && <td />}
										<td>
											<strong> {newAddress.doRukMeno}</strong>
										</td>
									</tr>
								)}
							</>
						)}
					</tbody>
				</table>
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	addresses: state.formAddresses.data
})

const mapDispatchToProps = (dispatch) => ({
	dispatch
})

export default compose(withTranslation('components'), connect(mapStateToProps, mapDispatchToProps))(AdresaPostovePoukazkyField)
