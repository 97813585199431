import {
	SIGN_PDF_DONE,
	SIGN_PDF_FAIL,
	SIGN_PDF_START,
	SIGN_PDF_CLEAR,
	SET_NOTIFICATION,
	SET_ADDITIONAL_NOTIFICATION,
	CLEAR_NOTIFICATION,
	TEMPLATE_PDF_LOAD_DONE,
	TEMPLATE_PDF_LOAD_FAIL,
	TEMPLATE_PDF_LOAD_START,
	SIGN_PDF_CANCEL,
	TEMPLATES_PDF_LOAD_DONE,
	TEMPLATES_PDF_LOAD_START,
	TEMPLATES_PDF_LOAD_FAIL,
	READ_PDF_CLEAR,
	READ_PDF_CANCEL,
	READ_PDF_START,
	READ_PDF_FAIL,
	READ_PDF_DONE
} from '../types/podpisovanieDokumentov'
import { RESET_STORAGE } from '../types/search'

export const initState = {
	signedPdf: {
		data: null,
		isLoading: false,
		isFailure: false
	},
	readPdf: {
		data: null,
		isLoading: false,
		isFailure: false
	},
	notification: null,
	additionalNotification: null,
	templatePdf: {
		data: null,
		isFailure: false,
		isLoading: false
	},
	templatesPdf: {
		data: null,
		isFailure: false,
		isLoading: false
	}
}

export default (state = initState, action) => {
	switch (action.type) {
		case SIGN_PDF_DONE:
			return {
				...state,
				signedPdf: {
					...initState.signedPdf,
					data: action.payload.data,
					isLoading: false,
					isFailure: false
				}
			}
		case SIGN_PDF_FAIL:
			return {
				...state,
				signedPdf: {
					...initState.signedPdf,
					data: null,
					isLoading: false,
					isFailure: true
				}
			}
		case SIGN_PDF_START:
			return {
				...state,
				signedPdf: {
					...initState.signedPdf,
					data: null,
					isLoading: true,
					isFailure: false
				}
			}
		case SIGN_PDF_CANCEL:
			return {
				...state,
				signedPdf: initState.signedPdf
			}
		case SIGN_PDF_CLEAR:
			return {
				...state,
				signedPdf: initState.signedPdf
			}
		case READ_PDF_DONE:
			return {
				...state,
				readPdf: {
					...initState.readPdf,
					data: action.payload.data,
					isLoading: false,
					isFailure: false
				}
			}
		case READ_PDF_FAIL:
			return {
				...state,
				readPdf: {
					...initState.readPdf,
					data: null,
					isLoading: false,
					isFailure: true
				}
			}
		case READ_PDF_START:
			return {
				...state,
				readPdf: {
					...initState.readPdf,
					data: null,
					isLoading: true,
					isFailure: false
				}
			}
		case READ_PDF_CANCEL:
			return {
				...state,
				readPdf: initState.readPdf
			}
		case READ_PDF_CLEAR:
			return {
				...state,
				readPdf: initState.readPdf
			}
		case SET_NOTIFICATION:
			return {
				...state,
				notification: action.payload.notification
			}
		case SET_ADDITIONAL_NOTIFICATION:
			return {
				...state,
				additionalNotification: action.payload.additionalNotification
			}
		case CLEAR_NOTIFICATION:
			return {
				...state,
				notification: null,
				additionalNotification: null
			}
		case TEMPLATE_PDF_LOAD_DONE:
			return {
				...state,
				templatePdf: {
					...initState.templatePdf,
					isLoading: false,
					isFailure: false,
					data: action.payload.templatePdf
				}
			}
		case TEMPLATE_PDF_LOAD_FAIL:
			return {
				...state,
				templatePdf: {
					...initState.templatePdf,
					isLoading: false,
					isFailure: true,
					data: null
				}
			}
		case TEMPLATE_PDF_LOAD_START:
			return {
				...state,
				templatePdf: {
					...initState.templatePdf,
					isLoading: true,
					isFailure: false,
					data: null
				}
			}
		case TEMPLATES_PDF_LOAD_DONE:
			return {
				...state,
				templatesPdf: {
					...initState.templatesPdf,
					isLoading: false,
					isFailure: false,
					data: action.payload.templatesPdf
				}
			}
		case TEMPLATES_PDF_LOAD_FAIL:
			return {
				...state,
				templatesPdf: {
					...initState.templatesPdf,
					isLoading: false,
					isFailure: true,
					data: null
				}
			}
		case TEMPLATES_PDF_LOAD_START:
			return {
				...state,
				templatesPdf: {
					...initState.templatesPdf,
					isLoading: true,
					isFailure: false,
					data: null
				}
			}
		case RESET_STORAGE:
			return {
				...initState
			}
		default:
			return state
	}
}
