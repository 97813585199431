import React from 'react'
import { get, size, head } from 'lodash'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { Tooltip } from 'react-tippy'
import { withTranslation } from 'react-i18next'
import cx from 'classnames'
import dayjs from 'dayjs'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import { isProduction } from '../utils/environment'

// actions
import InterakcieAction from '../actions/Interakcie'
import * as DigitalizaciaActions from '../actions/ObchodniPartneri/Digitalizacia'
import * as ExternalIntegrationsActions from '../actions/ExternalIntegrationsActions'

// field icons (edit + copy)
import SubheaderFieldIcons from './SubheaderFieldIcons/SubheaderFieldIcons'

// utils
import { formatDate, formatDateWithTime, getAge } from '../utils/date'
import { formatAddress } from '../utils/address'
import { getReq } from '../utils/request'
import { setRouteParams, DOKONCENIE_INTERAKCIE, VZTAHY_ZOZNAM, OP_DETAIL } from '../utils/routes'
import { checkPermissions, getMopException, PERMISSIONS } from '../utils/permissionsHoc'
import { STAV_INDIKATORA, OP_REGULACIA, DEFINICIA_TYP, UNKNOWN_DATE, DATE_FORMAT } from '../utils/enums'
import { getIsMop, getSegmentFromSkupinaOpravneni } from '../utils/obchodnyPartner'

// resources
import ElementLoading from './ElementLoading'
import DigiScore from './DigiScore'
import GeneratePin from './Modals/GeneratePin'
import ValidatePin from './Modals/ValidatePin'

dayjs.extend(isSameOrBefore)

class UserSubHeader extends React.Component {
	static propTypes = {
		auth: PropTypes.shape(),
		obchodnyPartner: PropTypes.shape({
			data: PropTypes.shape(),
			isLoading: PropTypes.bool.isRequired
		}).isRequired,
		interakcia: PropTypes.shape({
			data: PropTypes.shape({
				opCislo: PropTypes.string.isRequired
			}).isRequired
		}).isRequired,
		interakciaAction: PropTypes.shape().isRequired,
		btnEndInteractionDisabled: PropTypes.string,
		settings: PropTypes.shape().isRequired,
		overview: PropTypes.shape(),
		t: PropTypes.func.isRequired
	}

	_mounted = false

	constructor(props) {
		super(props)

		this.state = {
			showValidatePinModal: false,
			showGeneratePinModal: false,
			generatePinNotifikacia: null,
			obsluha: null,
			contactState: null
		}
	}

	componentWillUnmount() {
		this._mounted = false
	}

	loadDigiOverview = async () => {
		try {
			const { digitalizaciaActions } = this.props

			if (this._mounted) {
				this.setState({
					contactState: null
				})
			}

			const res = await digitalizaciaActions.loadDigitalizacia()

			const definicie = get(res, 'definicie', [])
			const telefonState = head(definicie?.filter((definicia) => definicia.definiciaId === DEFINICIA_TYP.KONTAKTNY_TELEFON))?.stav
			const emailState = head(definicie?.filter((definicia) => definicia.definiciaId === DEFINICIA_TYP.KONTAKTY_EMAIL))?.stav

			if (this._mounted) {
				this.setState({
					contactState: {
						[DEFINICIA_TYP.KONTAKTNY_TELEFON]: telefonState,
						[DEFINICIA_TYP.KONTAKTY_EMAIL]: emailState
					}
				})
			}
		} catch (e) {
			console.error(e)
			if (this._mounted) {
				this.setState({
					contactState: null
				})
			}
		}
	}

	getContactStyleByState = (state, value) => {
		if (value === '-') {
			return 'empty'
		}
		switch (state) {
			case STAV_INDIKATORA.RED:
				return 'red'
			case STAV_INDIKATORA.AMBER:
				return 'amber'
			default:
				return ''
		}
	}

	getAddressStyleByState = (aktualnost) => {
		if (!aktualnost) {
			return 'amber'
		}

		if (dayjs(aktualnost).isBefore(dayjs().subtract(180, 'days'))) {
			return 'amber'
		}

		return ''
	}

	// get at least one OM with limitovana obsluha param
	getOdberneMiestaWithLimitovanaObsluha = async () => {
		const { interakcia } = this.props
		const { obsluha } = this.state

		const opCislo = get(interakcia, 'data.opCislo')

		if (obsluha === null) {
			try {
				const res = await getReq(`/api/v0/obchodni-partneri/${opCislo}/odberne-miesta`, {
					velkostStranky: 1,
					stranka: 1,
					obsluhaLimitovana: true
				})

				const obsluha = size(get(res, 'response.obsah.odberneMiesta', [])) > 0 ? 'LO' : 'ŠO'
				if (this._mounted) {
					this.setState({
						obsluha
					})
				}
			} catch (e) {
				if (this._mounted) {
					this.setState({
						obsluha: null
					})
				}
			}
		}
	}

	componentDidMount() {
		this._mounted = true

		const { auth } = this.props

		this.loadDigiOverview()

		if (checkPermissions(get(auth, 'user.roles', []), [PERMISSIONS.VIEW_OP_ZMLUVY])) {
			this.getOdberneMiestaWithLimitovanaObsluha()
		}
	}

	getStyleByState = (state) => {
		// colors are defined in global.less
		switch (state) {
			case STAV_INDIKATORA.RED:
				return {
					backgroundColor: '#FFB6B6 !important',
					cursor: 'pointer'
				}
			case STAV_INDIKATORA.AMBER:
				return {
					backgroundColor: '#FDEB9C !important',
					cursor: 'pointer'
				}
			default:
				return {
					backgroundColor: '#FFFFFF !important',
					cursor: 'pointer'
				}
		}
	}

	formatPinStatusIcon = (isMop) => {
		const { interakcia, t } = this.props

		const pinStav = get(interakcia, 'data.pinStav')
		const pinPlatnostDo = get(interakcia, 'data.pinPlatnostDo')

		let pinIconClass
		let pinTooltipText

		switch (pinStav) {
			case 'NEPLATNY': {
				pinIconClass = 'pin-locked'
				pinTooltipText = <p>{t('translation:UserHeaderIcons.pin.Neoverený OP Vygenerujte PIN cez tlačidlá pri telefónnom čísle alebo emaile')}</p>
				break
			}
			case 'VYGENEROVANY': {
				pinIconClass = 'pin-waiting'
				pinTooltipText = (
					<>
						<p>{t('translation:UserHeaderIcons.pin.Neoverený OP s vygenerovaným PIN kódom Overte PIN so zákazníkom stlačením tejto ikony')}</p>
						<p>
							{t('translation:UserHeaderIcons.pin.PIN platný do {{ platnostDo }}', {
								platnostDo: formatDateWithTime(pinPlatnostDo)
							})}
						</p>
					</>
				)
				break
			}
			case 'OVERENY': {
				pinIconClass = 'pin-unlocked'
				pinTooltipText = <p>{t('translation:UserHeaderIcons.pin.Overený OP')}</p>
				break
			}
			default: {
				return null
			}
		}

		return (
			<Tooltip html={pinTooltipText} position='bottom' trigger='mouseenter' theme='light' disabled={isMop}>
				<a
					className={`header-field-icon pin ${pinIconClass}`}
					onClick={() => {
						if (isMop) {
							return
						}
						if (pinStav == 'VYGENEROVANY') {
							this.setState({
								showValidatePinModal: true
							})
						}
					}}
				/>
			</Tooltip>
		)
	}

	formatRegulacia = (regulovany, regulovanyGas) => {
		const regulacia = []
		if (regulovany) {
			regulacia.push(OP_REGULACIA.EE_REGULOVANY)
		} else if (regulovany === false) {
			regulacia.push(OP_REGULACIA.EE_NEREGULOVANY)
		}

		if (regulovanyGas) {
			regulacia.push(OP_REGULACIA.GAS_REGULOVANY)
		} else if (regulovanyGas === false) {
			regulacia.push(OP_REGULACIA.GAS_NEREGULOVANY)
		}

		return regulacia
	}

	formatDatumNarodenia = (narodenieDatum, umrtieDatum) => {
		const { t } = this.props

		if (narodenieDatum == undefined) return null

		let narodenieDatumString = formatDate(narodenieDatum, '-', DATE_FORMAT.RENDER_DATE)

		// Ak na OP neevidujeme dátum úmrtia, zobrazujeme vek
		if (umrtieDatum == undefined) {
			return `${narodenieDatumString} (${getAge(narodenieDatum)})`
		}

		// Ak na OP evidujeme dátum úmrtia ako neznámy, zobrazujeme vek
		const isUnknownUmrtieDatum = dayjs(umrtieDatum).isSame(UNKNOWN_DATE, 'day')
		if (isUnknownUmrtieDatum) {
			return `${narodenieDatumString} (${getAge(narodenieDatum)})`
		}

		return narodenieDatumString
	}

	formatDatumUmrtia = (narodenieDatum, umrtieDatum) => {
		const { t } = this.props

		if (umrtieDatum == undefined) return null

		const isUnknownUmrtieDatum = dayjs(umrtieDatum).isSame(UNKNOWN_DATE, 'day')
		let umrtieDatumString = isUnknownUmrtieDatum ? t('components:UserSubheader.Neznámy') : formatDate(umrtieDatum, '-', DATE_FORMAT.RENDER_DATE)

		if (isUnknownUmrtieDatum) {
			return umrtieDatumString
		}

		// Ak na OP evidujeme dátum úmrtia a poznáme dátum narodenia, zobrazujeme vek
		if (narodenieDatum != undefined) {
			return `${umrtieDatumString} (${getAge(narodenieDatum, umrtieDatum)})`
		}

		return umrtieDatumString
	}

	render() {
		const { obchodnyPartner, btnEndInteractionDisabled, settings, interakcia, auth, t, interakciaAction, overview } = this.props
		const { obsluha, showValidatePinModal, showGeneratePinModal, generatePinNotifikacia, contactState } = this.state

		if (obchodnyPartner.isLoading || !obchodnyPartner) {
			return <ElementLoading />
		}

		const skore = get(overview.data, 'skore', null)

		const opCislo = get(interakcia, 'data.opCislo')
		const telefon = get(obchodnyPartner, 'data.kontaktnyTelefon', '-') || '-'
		const telefonStav = contactState?.[DEFINICIA_TYP.KONTAKTNY_TELEFON]
		const email = get(obchodnyPartner, 'data.kontaktnyEmail', '-') || '-'
		const emailStav = contactState?.[DEFINICIA_TYP.KONTAKTY_EMAIL]
		// CP-2669
		let aktualnostAdresa = get(obchodnyPartner, 'data.aktualnostAdresa', null)
		if (dayjs(aktualnostAdresa).isSameOrBefore('1900-01-01', 'year')) {
			aktualnostAdresa = null
		}
		let aktualnostAdresaZakaznika = get(obchodnyPartner, 'data.aktualnostAdresaZakaznika', null)
		if (dayjs(aktualnostAdresaZakaznika).isSameOrBefore('1900', 'year')) {
			aktualnostAdresaZakaznika = null
		}

		const rating = get(obchodnyPartner, 'data.rating', '-')
		let ratingTooltip = null
		switch (rating) {
			case 'A':
				ratingTooltip = (
					<p>
						<strong>A</strong> - {t('components:UserSubheader.finančná situácia, hodnotenie platobnej morálky a dôvery sú výborné')}
					</p>
				)
				break
			case 'B':
				ratingTooltip = (
					<p>
						<strong>B</strong> - {t('components:UserSubheader.finančná situácia, hodnotenie platobnej morálky a dôvery sú veľmi dobré')}
					</p>
				)
				break
			case 'C':
				ratingTooltip = (
					<p>
						<strong>C</strong> -{' '}
						{t('components:UserSubheader.finančná situácia, hodnotenie platobnej morálky a dôvery sú dobré, vyžaduje sa neustály monitoring')}
					</p>
				)
				break
			case 'D':
				ratingTooltip = (
					<p>
						<strong>D</strong> - {t('components:UserSubheader.nízka dôvera, finančná situácia nie je dobrá, vyskytuje sa neplatenie pohľadávok')}
					</p>
				)
				break
			case 'E':
				ratingTooltip = (
					<p>
						<strong>E</strong> - {t('components:UserSubheader.má pohľadávky, hradí po splatnosti, finančná situácia zlá, žiadna dôvera')}
					</p>
				)
				break
		}
		const isMop = getIsMop(get(obchodnyPartner, 'data.skupinaOpravneni'))

		const meno =
			[
				get(obchodnyPartner, 'data.titulyPredMenom', ''),
				get(obchodnyPartner, 'data.meno', ''),
				get(obchodnyPartner, 'data.priezvisko', ''),
				get(obchodnyPartner, 'data.titulyZaMenom', '')
			]
				.join(' ')
				.trim() || '-'
		const obchodneMeno = get(obchodnyPartner, 'data.obchodneMeno', '-')

		const narodenieDatum = this.formatDatumNarodenia(obchodnyPartner.data.narodenieDatum, obchodnyPartner.data.umrtieDatum)
		const umrtieDatum = this.formatDatumUmrtia(obchodnyPartner.data.narodenieDatum, obchodnyPartner.data.umrtieDatum)

		const ico = get(obchodnyPartner, 'data.ICO', '-')
		const dic = get(obchodnyPartner, 'data.DIC', '-')
		const icDph = get(obchodnyPartner, 'data.ICDPH', '-')
		const adresaZakaznika = formatAddress(get(obchodnyPartner, 'data.adresaZakaznika'), false)
		const adresaZakaznikaDoRuk = get(obchodnyPartner, 'data.adresaZakaznika.doRukMeno')
		const adresaKorespondencna = formatAddress(get(obchodnyPartner, 'data.adresaKorespondencna'), false)
		const adresaKorespondencnaDoRuk = get(obchodnyPartner, 'data.adresaKorespondencna.doRukMeno')
		const segment = getSegmentFromSkupinaOpravneni(get(obchodnyPartner, 'data.skupinaOpravneni', null))
		const regulacie = this.formatRegulacia(get(obchodnyPartner, 'data.regulovany'), get(obchodnyPartner, 'data.regulovanyGas'))

		let link = (
			<Link to={setRouteParams(DOKONCENIE_INTERAKCIE, opCislo)}>
				<div className='button' data-type='outline' data-color='red'>
					{t('components:UserSubheader.Ukončiť interakciu')}
				</div>
			</Link>
		)

		if (btnEndInteractionDisabled) {
			link = (
				<div className='button disabled' data-type='outline' data-color='red'>
					{t('components:UserSubheader.Ukončiť interakciu')}
				</div>
			)
		}

		const canEditOp = checkPermissions(get(auth, 'user.roles', []), [PERMISSIONS.UKON_UDAJE_OP], [], getMopException(obchodnyPartner))

		const obsluhaCssClass = obsluha == 'LO' ? 'red' : 'blue'

		const canProcessPIN = checkPermissions(get(auth, 'user.roles', []), [PERMISSIONS.UKON_GENEROVANIE_PIN])

		return (
			<>
				{showGeneratePinModal && (
					<GeneratePin
						notifikacie={generatePinNotifikacia}
						onCloseButton={(refresh) => {
							if (refresh) {
								interakciaAction.refreshAcw()
							}
							this.setState({
								showGeneratePinModal: false,
								generatePinNotifikacia: null
							})
						}}
					/>
				)}
				{showValidatePinModal && (
					<ValidatePin
						onCloseButton={(refresh) => {
							if (refresh) {
								interakciaAction.refreshAcw()
							}
							this.setState({
								showValidatePinModal: false
							})
						}}
					/>
				)}
				<header className={isProduction(get(settings, 'env')) ? 'sub-header' : 'sub-header testing'}>
					<div className='column' style={{ minWidth: '200px' }}>
						<label>{t('components:UserSubheader.Obchodný partner')}</label>
						<div className='subheader-flex-section'>
							<h4>{get(obchodnyPartner, 'data.cislo', '-')} </h4>
							{get(obchodnyPartner, 'data.cislo') && <SubheaderFieldIcons textToCopy={get(obchodnyPartner, 'data.cislo')} />}
						</div>

						<div className='wrapper' style={{ display: 'inline', cursor: 'default', verticalAlign: 'top' }}>
							<Tooltip html={ratingTooltip} position='bottom' trigger='mouseenter' theme='light' disabled={!ratingTooltip}>
								<span className='square-label blue'>{rating}</span>
							</Tooltip>
						</div>
						{obsluha && (
							<div className='wrapper' style={{ display: 'inline', marginLeft: '10px', cursor: 'default', verticalAlign: 'top' }}>
								<Tooltip
									html={
										obsluha == 'LO' ? (
											<p>
												<b>{t('components:UserSubheader.Limitovaná obsluha')}</b> -{' '}
												{t(
													'components:UserSubheader.na OP je obmedzené vykonávanie úkonov, nakoľko aspoň na 1 OM bola historicky zhoršená platobná disciplína'
												)}
											</p>
										) : (
											<p>
												<b>{t('components:UserSubheader.Štandardná obsluha')}</b> -{' '}
												{t('components:UserSubheader.na OP nie je obmedzené vykonávanie úkonov')}
											</p>
										)
									}
									position='bottom'
									trigger='mouseenter'
									theme='light'
									disabled={false}
								>
									<span className={cx('square-label', obsluhaCssClass)}>{obsluha}</span>
								</Tooltip>
							</div>
						)}
						<div className='wrapper' style={{ display: 'inline', marginLeft: '10px', cursor: 'default', verticalAlign: 'top' }}>
							<DigiScore skore={skore} />
						</div>
					</div>
					<div className='column' style={{ maxWidth: '400px', minWidth: '200px', marginLeft: '-20px' }}>
						<div className='subheader-flex-section with-margin'>
							{canProcessPIN && this.formatPinStatusIcon(isMop)}
							{isMop ? (
								<>
									<div>
										<label>{t('components:UserSubheader.Obchodné meno')}</label>
										<div className={`value ${obchodneMeno === '-' ? 'empty' : ''}`}>{obchodneMeno}</div>
									</div>
									<SubheaderFieldIcons canEditOp={canEditOp} opCislo={opCislo} textToCopy={obchodneMeno} />
								</>
							) : (
								<>
									<div>
										<label>{t('components:UserSubheader.Meno OP')}</label>
										<div className={`value ${meno === '-' ? 'empty' : ''}`}>{meno}</div>
									</div>
									<SubheaderFieldIcons canEditOp={canEditOp} opCislo={opCislo} textToCopy={meno} />
								</>
							)}
						</div>
						{!isMop && (
							<div style={{ display: 'flex', gap: '40px' }}>
								<div className='subheader-flex-section'>
									<div style={{ marginLeft: canProcessPIN ? '35px' : '0px' }}>
										<label>{t('components:UserSubheader.Dátum narodenia')}</label>
										<div className={`value ${narodenieDatum === null ? 'empty' : ''}`}>{narodenieDatum ?? '-'}</div>
									</div>
									<SubheaderFieldIcons canEditOp={canEditOp} opCislo={opCislo} textToCopy={narodenieDatum} />
								</div>
								{umrtieDatum !== null && (
									<div className='subheader-flex-section'>
										<div>
											<label>{t('components:UserSubheader.Dátum úmrtia')}</label>
											<div className='value'>{umrtieDatum}</div>
										</div>
										<SubheaderFieldIcons canEditOp={canEditOp} opCislo={opCislo} textToCopy={umrtieDatum} />
									</div>
								)}
							</div>
						)}
					</div>
					{isMop && (
						<div className='column' style={{ minWidth: '200px' }}>
							<div className='subheader-flex-section with-margin'>
								<label className='inline-label'>{t('components:UserSubheader.IČO')}</label>
								<div className={`value ${ico === '-' ? 'empty' : ''}`}>{ico}</div>
								<SubheaderFieldIcons canEditOp={canEditOp} opCislo={opCislo} textToCopy={ico} />
							</div>
							<div className='subheader-flex-section with-margin'>
								<label className='inline-label'>{t('components:UserSubheader.DIČ')}</label>
								<div>{dic}</div>
								<SubheaderFieldIcons canEditOp={canEditOp} opCislo={opCislo} textToCopy={dic} />
							</div>
							<div className='subheader-flex-section'>
								<label className='inline-label'>{t('components:UserSubheader.IČ DPH')}</label>
								<div>{icDph}</div>
								<SubheaderFieldIcons canEditOp={canEditOp} opCislo={opCislo} textToCopy={icDph} />
							</div>
						</div>
					)}
					<div className='column' style={{ minWidth: '200px' }}>
						<div className='subheader-flex-section with-margin'>
							<div>
								<label>{isMop ? t('components:UserSubheader.Sídlo firmy') : t('components:UserSubheader.Adresa trvalého pobytu')}</label>
								<Tooltip
									html={
										<span>
											{t('components:UserSubheader.Aktualizované')} {formatDate(aktualnostAdresaZakaznika, '-', DATE_FORMAT.RENDER_DATE)}{' '}
										</span>
									}
									position='bottom'
									trigger='mouseenter'
									theme='light'
								>
									<div className={`value ${this.getAddressStyleByState(aktualnostAdresaZakaznika)}`}>{adresaZakaznika || '-'}</div>
								</Tooltip>
							</div>
							<SubheaderFieldIcons canEditOp={canEditOp} opCislo={opCislo} textToCopy={adresaZakaznika} />
						</div>
						<div className='subheader-flex-section'>
							<div>
								<Tooltip
									html={
										<>
											{!adresaKorespondencna && adresaZakaznika && (
												<>
													<span>{t('components:UserSubheader.Odvodená adresa z')}</span>
													<br />
													<span>
														<strong>{t('components:UserSubheader.Adresa trvalého pobytu')}</strong>
													</span>
													<div style={{ marginBottom: 10 }} />
												</>
											)}
											<div>
												{t('components:UserSubheader.Aktualizované')} {formatDate(aktualnostAdresa, '-', DATE_FORMAT.RENDER_DATE)}
											</div>
										</>
									}
									position='bottom'
									trigger='mouseenter'
									theme='light'
								>
									<label>{t('components:UserSubheader.Korešpondenčná adresa')}</label>
									<div
										className={`value ${this.getAddressStyleByState(aktualnostAdresa)}`}
										style={{ position: 'relative', paddingRight: '20px' }}
									>
										{adresaKorespondencna || adresaZakaznika || '-'}
										<br />
										{adresaKorespondencnaDoRuk && `(${adresaKorespondencnaDoRuk})`}
										{!adresaKorespondencna && adresaZakaznikaDoRuk && `(${adresaZakaznikaDoRuk})`}
									</div>
								</Tooltip>
							</div>
							<SubheaderFieldIcons canEditOp={canEditOp} opCislo={opCislo} textToCopy={adresaKorespondencna || adresaZakaznika} />
						</div>
					</div>
					<div className='column' style={{ minWidth: '200px' }}>
						<div className='subheader-flex-section with-margin'>
							{canProcessPIN && get(interakcia, 'data.pinStav') != 'OVERENY' && get(obchodnyPartner, 'data.kontaktnyTelefon') && (
								<Tooltip
									html={t('translation:UserSubheader.Odoslať PIN ako SMS')}
									position='bottom'
									trigger='mouseenter'
									theme='light'
									disabled={isMop}
								>
									<a
										className={`header-field-icon pin pin-phone`}
										onClick={() => {
											if (isMop) {
												return
											}
											this.setState({
												showGeneratePinModal: true,
												generatePinNotifikacia: {
													adresyPosta: [],
													adresyUri: [
														{
															typ: 'MOBIL',
															hodnota: get(obchodnyPartner, 'data.kontaktnyTelefon')
														}
													]
												}
											})
										}}
									/>
								</Tooltip>
							)}
							<Tooltip
								html={
									<span>
										{t('components:UserSubheader.Aktualizované')}{' '}
										{formatDate(get(obchodnyPartner, 'data.aktualnostTelefon'), '-', DATE_FORMAT.RENDER_DATE)}{' '}
									</span>
								}
								position='bottom'
								trigger='mouseenter'
								theme='light'
							>
								<div>
									<label>{t('components:UserSubheader.Tel číslo')}</label>
									<div className={`value ${this.getContactStyleByState(telefonStav)}`}>{telefon}</div>
								</div>
							</Tooltip>
							<SubheaderFieldIcons canEditOp={canEditOp} opCislo={opCislo} textToCopy={telefon} />
						</div>
						<div className='subheader-flex-section'>
							{canProcessPIN && get(interakcia, 'data.pinStav') != 'OVERENY' && get(obchodnyPartner, 'data.kontaktnyEmail') && (
								<Tooltip
									html={t('translation:UserSubheader.Odoslať PIN ako E-MAIL')}
									position='bottom'
									trigger='mouseenter'
									theme='light'
									disabled={isMop}
								>
									<a
										className={`header-field-icon pin pin-mail`}
										onClick={() => {
											if (isMop) {
												return
											}
											this.setState({
												showGeneratePinModal: true,
												generatePinNotifikacia: {
													adresyPosta: [],
													adresyUri: [
														{
															typ: 'EMAIL',
															hodnota: get(obchodnyPartner, 'data.kontaktnyEmail')
														}
													]
												}
											})
										}}
									/>
								</Tooltip>
							)}
							<Tooltip
								html={
									<span>
										{t('components:UserSubheader.Aktualizované')}{' '}
										{formatDate(get(obchodnyPartner, 'data.aktualnostEmail'), '-', DATE_FORMAT.RENDER_DATE)}{' '}
									</span>
								}
								position='bottom'
								trigger='mouseenter'
								theme='light'
							>
								<div>
									<label>{t('components:UserSubheader.E-mail')}</label>
									<div className={`value ${this.getContactStyleByState(emailStav)}`}>{email}</div>
								</div>
							</Tooltip>
							<SubheaderFieldIcons canEditOp={canEditOp} opCislo={opCislo} textToCopy={email} />
						</div>
					</div>
					{isMop && (
						<div className='column' style={{ minWidth: '100px' }}>
							<div className='subheader-flex-section with-margin'>
								<div>
									<label>{t('components:UserSubheader.Segment')}</label>
									<Link to={`${setRouteParams(VZTAHY_ZOZNAM, opCislo)}?typ=ZZPSME,ZZPSMT,ZMCKAM,ZMCAM`}>
										<div className={`value ${segment === null ? 'empty' : ''}`}>{segment ?? '-'}</div>
									</Link>
								</div>
							</div>
							<div className='subheader-flex-section'>
								<div>
									<label>{t('components:UserSubheader.Regulácia')}</label>
									<Link to={`${setRouteParams(OP_DETAIL, opCislo)}?regulaciaStav=aktivne&marketingDolezite=true#regulacie`}>
										<div className={`value regulacie-container`}>
											{regulacie.map((regulacia, index) => {
												return (
													<span key={index} className='regulacie-box'>
														{regulacia}
													</span>
												)
											})}
											{regulacie.length === 0 && '-'}
										</div>
									</Link>
								</div>
							</div>
						</div>
					)}
					<div className='column right'>{link}</div>
				</header>
			</>
		)
	}
}

const mapStateToProps = (state) => ({
	auth: state.auth,
	obchodnyPartner: state.obchodnyPartner.detail,
	interakcia: state.interakcie.detail,
	settings: get(state, 'settings.settings'),
	overview: get(state, 'obchodnyPartner.digitalizacia'),
	liveAgentSession: get(state, 'externalIntegrations.liveAgentId')
})

const mapDispatchToProps = (dispatch) => ({
	interakciaAction: bindActionCreators(InterakcieAction, dispatch),
	digitalizaciaActions: bindActionCreators(DigitalizaciaActions, dispatch),
	externalIntegrationsActions: bindActionCreators(ExternalIntegrationsActions, dispatch)
})

export default withTranslation('components')(connect(mapStateToProps, mapDispatchToProps)(UserSubHeader))
