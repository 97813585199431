import React from 'react'
import { Field } from 'redux-form'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { Tooltip } from 'react-tippy'

// atoms
import { TextInputField } from '../../../atoms'

// config
import { COLUMNS_COUNT, EDIT_MODE } from '../../../containers/GenericUkon/genericUkonConfig'

class OznamovatelUmrtiaField extends React.Component {
	static propTypes = {
		field: PropTypes.string.isRequired,
		editMode: PropTypes.string.isRequired,
		columnsCount: PropTypes.string,
		t: PropTypes.func.isRequired,
		value: PropTypes.shape()
	}

	_mounted = false

	state = {}

	constructor(props) {
		super(props)
	}

	componentDidMount() {
		this._mounted = true
	}

	componentWillUnmount() {
		this._mounted = false
	}

	validate = (value) => {
		const { t, povinny } = this.props

		if (povinny && !value) {
			return t('components:GenericFields.Toto pole je povinné')
		}
	}

	render() {
		const { field, editMode, columnsCount = COLUMNS_COUNT.TWO, t, value } = this.props

		return (
			<div className='inner-box'>
				<table className='content-table padded bordered' cellSpacing='0'>
					<tbody>
						<tr>
							<td>
								<strong>{t('components:GenericFields.Oznamovateľ úmrtia')}</strong>
								<Tooltip
									html={
										<span>
											{t(
												'components:GenericFields.V časti oznamovateľ evidujeme meno priezvisko oznamovateľa/vzťah k zosnulému Ide o nepovinný údaj'
											)}
										</span>
									}
									position='bottom'
									trigger='mouseenter'
									theme='light'
								>
									<div className='info-icon' />
								</Tooltip>
							</td>
							{columnsCount === COLUMNS_COUNT.THREE && <td />}
							{(editMode == EDIT_MODE.EDIT || editMode == EDIT_MODE.CREATE) && (
								<td>
									<Field name={field} component={TextInputField} validate={this.validate} />
								</td>
							)}
							{(editMode == EDIT_MODE.CONFIRM || editMode == EDIT_MODE.DETAIL) && <td>{value || '-'}</td>}
						</tr>
					</tbody>
				</table>
			</div>
		)
	}
}

export default compose(withTranslation('components'))(OznamovatelUmrtiaField)
