import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { filter, get, find, orderBy, head, map, cloneDeep, nth, isEmpty, forEach, isEqual, omit } from 'lodash'
import { bindActionCreators, compose } from 'redux'
import { initialize, reset, change, touch, destroy, getFormValues } from 'redux-form'
import dayjs from 'dayjs'
import { withTranslation } from 'react-i18next'

// components
import ObchodnyPartnerEditInfo from '../../components/ObchodnyPartner/ObchodnyPartnerEditForm/ObchodnyPartnerEditInfo'
import ObchodnyPartnerEditConfirm from '../../components/ObchodnyPartner/ObchodnyPartnerEditForm/ObchodnyPartnerEditConfirm'
import DefaultModal from '../../components/Modals/DefaultModal'
import ElementLoading from '../../components/ElementLoading'
import ElementFailure from '../../components/ElementFailure'

// utils
import { history } from '../../utils/history'
import { postReq, putReq, deleteReq } from '../../utils/request'
import { OP_DETAIL, setRouteParams } from '../../utils/routes'
import { formatAddress, formatAddressForResponse } from '../../utils/address'
import { getUkonVstupIdByBiznisKanal } from '../../utils/scenar'
import { createFormInitValues } from '../../utils/form'
import { withPermissions, PERMISSIONS } from '../../utils/permissionsHoc'
import {
	FORMS,
	SCENARE,
	NOTIFICATION_TYPES,
	UKONY,
	BUSINESS_CHANNELS,
	UKONY_CISELNIK,
	FILTER_SELECTORS,
	DOKUMENT_TYP,
	DEFINICIA_TYP,
	TYP_SUHLASU,
	SUHLAS_HODNOTA,
	BIZNIS_REFERENCIA_TYP
} from '../../utils/enums'
import { areDifferentNesuhlas } from '../../utils/suhlas'

// actions
import * as TrackingActions from '../../actions/TrackingActions'
import * as UkonyActions from '../../actions/UkonyActions'
import ObchodnyPartnerActions from '../../actions/ObchodniPartneri'
import * as FormAddresssesActions from '../../actions/FormAddresses'
import * as DataActions from '../../actions/DataActions'
import * as UkonEditOpActions from '../../actions/UkonEditOpActions'
import InterakcieActions from '../../actions/Interakcie'

const scenarioOptions = [
	{
		kanal: BUSINESS_CHANNELS.ZSE_CENTRUM,
		scenarios: [{ typ: SCENARE.PODPISANIE_NA_MIESTE }, { typ: SCENARE.VYTLACIT_A_NAHRAT }, { typ: SCENARE.NAHRAT }],
		notificationRequired: true,
		documentRequired: true,
		notificationTypes: [NOTIFICATION_TYPES.EMAIL, NOTIFICATION_TYPES.PRINTER, NOTIFICATION_TYPES.ADDRESS]
	},
	{
		kanal: BUSINESS_CHANNELS.ZAKAZNICKA_LINKA,
		scenarios: [{ typ: SCENARE.ODOSLAT_BEZ_PODPISU }],
		notificationRequired: true,
		documentRequired: true,
		notificationTypes: [NOTIFICATION_TYPES.EMAIL, NOTIFICATION_TYPES.PRINTER, NOTIFICATION_TYPES.ADDRESS, NOTIFICATION_TYPES.VOID_NOTIFICATION]
	},
	{
		kanal: BUSINESS_CHANNELS.BACK_OFFICE,
		scenarios: [{ typ: SCENARE.ODOSLAT_BEZ_PODPISU }],
		notificationRequired: true,
		documentRequired: true,
		notificationTypes: [NOTIFICATION_TYPES.EMAIL, NOTIFICATION_TYPES.PRINTER, NOTIFICATION_TYPES.ADDRESS, NOTIFICATION_TYPES.VOID_NOTIFICATION]
	}
]

class ObchodnyPartnerEditPage extends React.Component {
	static propTypes = {
		dispatch: PropTypes.func.isRequired,
		t: PropTypes.func.isRequired,
		interakcia: PropTypes.shape().isRequired,
		auth: PropTypes.shape().isRequired,
		tracking: PropTypes.shape().isRequired,
		ukonNovy: PropTypes.shape(),
		ciselniky: PropTypes.shape().isRequired,
		signedPdf: PropTypes.shape(),
		notification: PropTypes.shape(),
		templatePdf: PropTypes.shape(),
		addresses: PropTypes.arrayOf(PropTypes.shape()).isRequired,
		obchodnyPartner: PropTypes.shape().isRequired,
		zmluvneUcty: PropTypes.shape().isRequired,
		aktivneZmluvneUcty: PropTypes.shape().isRequired,
		ukonEditOp: PropTypes.shape().isRequired,
		formValues: PropTypes.shape(),
		ukonyActions: PropTypes.shape().isRequired,
		trackingActions: PropTypes.shape().isRequired,
		dataActions: PropTypes.shape().isRequired,
		formAddressesActions: PropTypes.shape().isRequired,
		obchodnyPartnerActions: PropTypes.shape().isRequired,
		ukonEditOpActions: PropTypes.shape().isRequired,
		interakcieActions: PropTypes.shape().isRequired,
		selectedFiltersUkony: PropTypes.shape()
	}

	constructor(props) {
		super(props)

		// filter possible scenars for signing pdf files
		const scenarioOption = filter(scenarioOptions, (scenarioOption) => {
			return get(scenarioOption, 'kanal') == get(props, 'auth.businessChannel.actual.id')
		})

		this.state = {
			step: 1,
			numOfSteps: 2,
			scenarioOption: head(scenarioOption),
			originalValues: {},
			originalValuesInfo: {},
			result: null,
			success: false,
			isLoading: true,
			isDisabledGlobalZmenaAdresaZakaznika: true,
			isDisabledGlobalZmenaAdresaKorespondencna: true
		}
	}

	// NOTE: compare active ZU addresses with adresaZakaznika and adresaKorespondencna
	checkDiffAddress = (address, zmluvnyUcet) => {
		if (
			address?.id !== zmluvnyUcet?.adresaDoslePlatby?.id ||
			address?.id !== zmluvnyUcet?.adresaOdoslanePlatby?.id ||
			address?.id !== zmluvnyUcet?.adresaVseobecna?.id ||
			address?.id !== zmluvnyUcet?.adresaUpominacia?.id ||
			address?.id !== zmluvnyUcet?.adresaFakturacie?.id ||
			address?.id !== zmluvnyUcet?.adresaDane?.id
		) {
			return false
		}
		return true
	}

	async componentDidMount() {
		this._mounted = true

		const {
			interakcia,
			obchodnyPartner,
			tracking,
			trackingActions,
			zmluvneUcty,
			obchodnyPartnerActions,
			ciselniky,
			auth,
			dispatch,
			dataActions,
			ukonEditOpActions,
			formAddressesActions
		} = this.props

		const { allDataLoading, allDataPrepared } = zmluvneUcty

		const opCislo = get(interakcia, 'opCislo')

		dataActions.registerLeavePageModal()

		if (!get(tracking, 'startTime') || (get(tracking, 'startTime') && get(tracking, 'type') !== UKONY.ZMENA_UDAJOV_OP)) {
			trackingActions.tryToStartTracking(UKONY.ZMENA_UDAJOV_OP)
		}

		if (!allDataPrepared && !allDataLoading) {
			obchodnyPartnerActions.loadZmluvneUcty()
		}

		const aktivneZmluvneUcty = await obchodnyPartnerActions.loadActiveZmluvneUcty(opCislo)

		let isSameAddressesAdresaZakaznika = true
		let isSameAddressesAdresaKorespondencna = true

		if (!isEmpty(aktivneZmluvneUcty)) {
			aktivneZmluvneUcty.forEach((zu) => {
				if (isSameAddressesAdresaZakaznika) {
					isSameAddressesAdresaZakaznika = this.checkDiffAddress(obchodnyPartner?.adresaZakaznika, zu)
				}
				if (isSameAddressesAdresaKorespondencna) {
					isSameAddressesAdresaKorespondencna = this.checkDiffAddress(obchodnyPartner?.adresaKorespondencna, zu)
				}
				if (isSameAddressesAdresaZakaznika && isSameAddressesAdresaKorespondencna) {
					return null
				}
			})
		}

		const addresses = [...map(get(obchodnyPartner, 'adresy', []), (address) => cloneDeep(address))]

		formAddressesActions.formAddressesInit(addresses)

		const ukonPromise = ukonEditOpActions.loadDataForUkonEditOp(get(interakcia, 'opCislo'))
		const nesuhlasyPromise = ukonEditOpActions.loadNesuhlasForContact(opCislo)

		const results = await Promise.all([ukonPromise, nesuhlasyPromise])

		const initData = results?.[0]
		const nesuhlasy = results?.[1]

		let telefon = head(nesuhlasy?.definicie?.filter((nesuhlas) => nesuhlas.id === DEFINICIA_TYP.KONTAKTNY_TELEFON))
		let email = head(nesuhlasy?.definicie?.filter((nesuhlas) => nesuhlas.id === DEFINICIA_TYP.KONTAKTY_EMAIL))
		const isValidAktualnyNesuhlasTelefon = telefon?.aktualnyNesuhlas?.isValid
		const isValidAktualnyNesuhlasEmail = email?.aktualnyNesuhlas?.isValid

		if (telefon?.aktualnyNesuhlas) {
			telefon = {
				...telefon,
				aktualnyNesuhlas: {
					...telefon.aktualnyNesuhlas,
					...(telefon?.nesuhlasPoznamka && { nesuhlasPoznamka: telefon?.nesuhlasPoznamka, nesuhlasDovodIny: telefon?.nesuhlasPoznamka }),
					hodnota: SUHLAS_HODNOTA.NAMIETKA
				}
			}
		}

		if (email?.aktualnyNesuhlas) {
			email = {
				...email,
				aktualnyNesuhlas: {
					...email.aktualnyNesuhlas,
					...(email?.nesuhlasPoznamka && { nesuhlasPoznamka: email?.nesuhlasPoznamka, nesuhlasDovodIny: email?.nesuhlasPoznamka }),
					hodnota: SUHLAS_HODNOTA.NAMIETKA
				}
			}
		}

		const dataTitulyPred = orderBy(
			filter(get(initData, 'data.tituly'), (titul) => {
				return get(titul, 'hodnota.pred_menom')
			}),
			['poradie', 'asc']
		)
		const dataTitulyZa = filter(get(initData, 'data.tituly'), (titul) => {
			return !get(titul, 'hodnota.pred_menom')
		})
		const dataTitulPred1 = nth(dataTitulyPred, 0)
		const dataTitulPred2 = nth(dataTitulyPred, 1)
		const dataTitulZa = head(orderBy(dataTitulyZa, ['poradie'], ['asc']))

		const dataAdresaZakaznika = get(initData, 'data.adresaZakaznika')
		const dataAdresaKorespondencna = get(initData, 'data.adresaKorespondencna')

		const initValues = createFormInitValues({
			ukonVstup: getUkonVstupIdByBiznisKanal(get(auth, 'businessChannel.actual.id'), get(ciselniky, 'ukonVstup')),
			datumPrijatiaZiadosti: new Date(),
			podpisMiesto: get(auth, 'user.podpisMiesto') || null,
			splnomocnenec: null,
			titulPred1: get(dataTitulPred1, 'hodnota.id') || null,
			titulPred2: get(dataTitulPred2, 'hodnota.id') || null,
			titulZa: get(dataTitulZa, 'hodnota.id') || null,
			meno: get(initData, 'data.meno'),
			priezvisko: get(initData, 'data.priezvisko'),
			narodenieDatum: get(initData, 'data.narodenieDatum') ? new Date(get(initData, 'data.narodenieDatum')) : null,
			adresaZakaznika: dataAdresaZakaznika
				? {
						...dataAdresaZakaznika,
						globalnaZmena: false,
						doRukMeno: dataAdresaZakaznika?.doRukMeno ? dataAdresaZakaznika.doRukMeno : null
				  }
				: null,
			adresaKorespondencna: dataAdresaKorespondencna
				? {
						...dataAdresaKorespondencna,
						globalnaZmena: false,
						doRukMeno: dataAdresaKorespondencna?.doRukMeno ? dataAdresaKorespondencna.doRukMeno : null
				  }
				: null,
			kontaktnyTelefon: get(initData, 'data.kontaktnyTelefon') || null,
			aktualnostTelefon: get(initData, 'data.aktualnostTelefon') || null,
			suhlasKontaktnyTelefon: isValidAktualnyNesuhlasTelefon ? get(telefon, 'aktualnyNesuhlas') : null,
			kontaktnyEmail: get(initData, 'data.kontaktnyEmail') || null,
			aktualnostEmail: get(initData, 'data.aktualnostEmail') || null,
			suhlasKontaktnyEmail: isValidAktualnyNesuhlasEmail ? get(email, 'aktualnyNesuhlas') : null,
			aktualnostAdresa: get(initData, 'data.aktualnostAdresa') || null,
			aktualnostAdresaZakaznika: get(initData, 'data.aktualnostAdresaZakaznika') || null,
			eFaktury: [],
			denSplatnostiPreferovany: get(initData, 'data.denSplatnostiPreferovany') || null,
			poznamka: '',
			dokumenty: []
		})

		dispatch(initialize(FORMS.OBCHODNY_PARTNER_EDITACIA, initValues, true))
		dispatch(reset(FORMS.OBCHODNY_PARTNER_EDITACIA))

		const dataPredTitulyPred = orderBy(
			filter(get(initData, 'dataPred.tituly'), (titul) => {
				return get(titul, 'hodnota.pred_menom')
			}),
			['poradie', 'asc']
		)
		const dataPredTitulyZa = filter(get(initData, 'dataPred.tituly'), (titul) => {
			return !get(titul, 'hodnota.pred_menom')
		})
		const dataPredTitulPred1 = nth(dataPredTitulyPred, 0)
		const dataPredTitulPred2 = nth(dataPredTitulyPred, 1)
		const dataPredTitulZa = head(orderBy(dataPredTitulyZa, ['poradie'], ['asc']))

		const dataPredAdresaZakaznika = get(initData, 'dataPred.adresaZakaznika')
		const dataPredAdresaKorespondencnaInherit = this.inheritAddress(get(initData, 'dataPred'), 'adresaKorespondencna')
		const dataPredAdresaKorespondencnaInheritInfo = this.inheritAddress(get(initData, 'dataPred'), 'adresaKorespondencna', true)

		const originalValues = {
			ukonVstup: getUkonVstupIdByBiznisKanal(get(auth, 'businessChannel.actual.id'), get(ciselniky, 'ukonVstup')),
			podpisMiesto: get(auth, 'user.podpisMiesto') || null,
			splnomocnenec: null,
			titulPred1: dataPredTitulPred1 || null,
			titulPred2: dataPredTitulPred2 || null,
			titulZa: dataPredTitulZa || null,
			meno: get(initData, 'dataPred.meno'),
			priezvisko: get(initData, 'dataPred.priezvisko'),
			narodenieDatum: get(initData, 'dataPred.narodenieDatum') ? new Date(get(initData, 'dataPred.narodenieDatum')) : null,
			adresaZakaznika: dataPredAdresaZakaznika || null,
			adresaKorespondencna: get(initData, 'dataPred.adresaKorespondencna') || null,
			adresaKorespondencnaSection: get(dataPredAdresaKorespondencnaInherit, 'adresaKorespondencnaSection') || null,
			adresaKorespondencnaSectionInherit: get(dataPredAdresaKorespondencnaInherit, 'adresaKorespondencnaSectionInherit') || null,
			kontaktnyTelefon: get(initData, 'dataPred.kontaktnyTelefon') || null,
			aktualnostTelefon: get(initData, 'dataPred.aktualnostTelefon') || null,
			suhlasKontaktnyTelefon: get(telefon, 'aktualnyNesuhlas') || null,
			kontaktnyEmail: get(initData, 'dataPred.kontaktnyEmail') || null,
			aktualnostEmail: get(initData, 'dataPred.aktualnostEmail') || null,
			suhlasKontaktnyEmail: get(email, 'aktualnyNesuhlas') || null,
			aktualnostAdresa: get(initData, 'data.aktualnostAdresa') || null,
			aktualnostAdresaZakaznika: get(initData, 'data.aktualnostAdresaZakaznika') || null,
			eFaktury: [],
			denSplatnostiPreferovany: get(initData, 'dataPred.denSplatnostiPreferovany') || null
		}

		const originalValuesInfo = {
			...originalValues,
			adresaKorespondencnaSection: get(dataPredAdresaKorespondencnaInheritInfo, 'adresaKorespondencnaSection') || null,
			adresaKorespondencnaSectionInherit: get(dataPredAdresaKorespondencnaInheritInfo, 'adresaKorespondencnaSectionInherit') || null
		}

		dispatch(touch(FORMS.OBCHODNY_PARTNER_EDITACIA, 'narodenieDatum'))

		this.setState({
			originalValues,
			originalValuesInfo,
			isDisabledGlobalZmenaAdresaZakaznika: isSameAddressesAdresaZakaznika,
			isDisabledGlobalZmenaAdresaKorespondencna: isSameAddressesAdresaKorespondencna
		})

		if (!get(initValues, 'ukonVstup')) {
			// trigger validation immediately after init form
			dispatch(touch(FORMS.OBCHODNY_PARTNER_EDITACIA, 'ukonVstup'))
		}
		if (get(auth, 'businessChannel.actual.id') == BUSINESS_CHANNELS.BACK_OFFICE && isEmpty(get(initValues, 'dokumenty'))) {
			// trigger validation immediately after init form
			dispatch(touch(FORMS.OBCHODNY_PARTNER_EDITACIA, 'dokumenty'))
		}

		this.setLoading(false)
	}

	componentWillUnmount() {
		const { dispatch, trackingActions, dataActions, ukonyActions } = this.props

		dispatch(destroy(FORMS.OBCHODNY_PARTNER_EDITACIA))

		dataActions.unregisterLeavePageModal()
		trackingActions.clearTracking()

		// clear ukonId from storage
		ukonyActions.clearUkonId()

		this._mounted = false
	}

	inheritAddress = (data, key, formatWithHands = false) => {
		const { t } = this.props

		let adresa = null
		let adresaSectionInherit = ''
		let adresaSection = '-'
		if (get(data, key)) {
			adresa = get(data, key, null)
			adresaSection = formatAddress(get(data, key), formatWithHands)
		} else if (get(data, 'adresaZakaznika')) {
			adresa = get(data, 'adresaZakaznika', null)
			adresaSectionInherit = t('translation:Common.Adresa trvalého pobytu')
			adresaSection = formatAddress(get(data, 'adresaZakaznika'), formatWithHands)
		}

		return {
			[`${key}`]: adresa,
			[`${key}SectionInherit`]: adresaSectionInherit,
			[`${key}Section`]: adresaSection
		}
	}

	setLoading = (value) => {
		this.setState({
			isLoading: value
		})
	}

	setStep = (step) => {
		const { numOfSteps } = this.state

		if (step < 1 || step > numOfSteps) {
			return
		}
		this.setState({
			step
		})
	}

	formatDataForUkon = (values) => {
		const { ciselniky, interakcia, auth, tracking, addresses } = this.props

		const typ = find(get(ciselniky, 'ukonTyp', []), (ukonTyp) => {
			return get(ukonTyp, 'id') == UKONY_CISELNIK.ZMENA_UDAJOV_OP
		})
		const vstup = find(get(ciselniky, 'ukonVstup', []), (vstup) => {
			return get(vstup, 'id') == get(values, 'ukonVstup')
		})
		const trvanie = dayjs().diff(dayjs(get(tracking, 'startTime')), 'millisecond')
		const zacatyOd = dayjs(get(tracking, 'startTime')).toISOString()
		const ziadanyOd = dayjs(get(values, 'datumPrijatiaZiadosti')).toISOString()

		const tituly = []
		forEach(get(ciselniky, 'akademickyTitulHodnota', []), (titul) => {
			if (get(values, 'titulPred1') && get(titul, 'id') == get(values, 'titulPred1')) {
				tituly.push({
					poradie: 1,
					hodnota: titul
				})
			}
			if (get(values, 'titulPred2') && get(titul, 'id') == get(values, 'titulPred2')) {
				tituly.push({
					poradie: 2,
					hodnota: titul
				})
			}
			if (get(values, 'titulZa') && get(titul, 'id') == get(values, 'titulZa')) {
				tituly.push({
					poradie: 1,
					hodnota: titul
				})
			}
		})

		const narodenieDatum = get(values, 'narodenieDatum') ? dayjs(get(values, 'narodenieDatum')).format('YYYY-MM-DD') : ''

		const adresaZakaznika = formatAddressForResponse('adresaZakaznika', values, addresses)
		let adresaKorespondencna
		let nastavAdresyZU
		if (get(values, 'adresaZakaznika.globalnaZmena')) {
			adresaKorespondencna = adresaZakaznika
			nastavAdresyZU = get(adresaZakaznika, 'id', undefined)
		} else {
			adresaKorespondencna = formatAddressForResponse('adresaKorespondencna', values, addresses)
			if (get(values, 'adresaKorespondencna.globalnaZmena')) {
				nastavAdresyZU = get(adresaKorespondencna, 'id', undefined)
			}
		}

		const efakturaDodatocneEmaily = map(get(values, 'eFaktury', []), (eFaktura) => {
			return get(eFaktura, 'email')
		})

		let aktualnostEmail = null
		let aktualnostTelefon = null
		if (values?.aktualnostEmail) {
			aktualnostEmail = get(values, 'aktualnostEmail') || null
		}
		if (values?.aktualnostTelefon) {
			aktualnostTelefon = get(values, 'aktualnostTelefon') || null
		}

		return {
			typ,
			vstup,
			opCislo: get(interakcia, 'opCislo'),
			interakciaId: get(interakcia, 'id'),
			riesitel: get(auth, 'user.id'),
			kanal: get(auth, 'businessChannel.actual'),
			trvanie,
			zacatyOd,
			podpisMiesto: get(values, 'podpisMiesto'),
			poznamka: get(values, 'poznamka') || null,
			ziadanyOd,
			splnomocnenec: get(values, 'splnomocnenec'),
			data: {
				meno: get(values, 'meno'),
				priezvisko: get(values, 'priezvisko'),
				tituly,
				narodenieDatum,
				kontaktnyTelefon: get(values, 'kontaktnyTelefon'),
				suhlasKontaktnyTelefon: get(values, 'suhlasKontaktnyTelefon') || null,
				aktualnostTelefon,
				kontaktnyEmail: get(values, 'kontaktnyEmail'),
				suhlasKontaktnyEmail: get(values, 'suhlasKontaktnyEmail') || null,
				aktualnostEmail,
				efakturaDodatocneEmaily,
				kontaktnyEmailEFaktura: get(values, 'uplatnitEfaktury'),
				adresaZakaznika,
				aktualnostAdresa: get(values, 'aktualnostAdresa') || null,
				adresaKorespondencna,
				aktualnostAdresaZakaznika: get(values, 'aktualnostAdresaZakaznika') || null,
				denSplatnostiPreferovany: get(values, 'denSplatnostiPreferovany'),
				nastavAdresyZU
			}
		}
	}

	prepareSuhlas = ({ suhlasData, definiciaId, opCislo, kanal, pouzivatel, pouzivatelIdentifikator, biznisReferenciaTyp, biznisReferencia }) => {
		return {
			// suhlasId: '',
			datumVyjadrenie: suhlasData?.platnostOd,
			definiciaId,
			kanal,
			nesuhlasDovod: suhlasData?.nesuhlasDovod,
			nesuhlasPoznamka: suhlasData?.nesuhlasDovodIny,
			opCislo,
			platnostOd: suhlasData?.platnostOd,
			pouzivatel,
			pouzivatelIdentifikator,
			typVyjadrenieSuhlas: TYP_SUHLASU.SUHLAS,
			biznisReferenciaTyp,
			biznisReferencia
		}
	}

	formDataForSuhlasy = ({ values, opCislo, auth, interakcia, ukonId }) => {
		const { originalValues } = this.state
		const suhlasy = []

		const suhlas = {
			opCislo,
			kanal: interakcia?.kanal?.id,
			pouzivatel: get(auth, 'user.displayName'),
			pouzivatelIdentifikator: get(auth, 'user.id'),
			biznisReferenciaTyp: BIZNIS_REFERENCIA_TYP.UKON_ID.id,
			biznisReferencia: ukonId
		}

		if (!isEmpty(values?.suhlasKontaktnyTelefon?.nesuhlasDovod) && values.suhlasKontaktnyTelefon?.hodnota === SUHLAS_HODNOTA.NAMIETKA) {
			if (areDifferentNesuhlas(originalValues?.suhlasKontaktnyTelefon, values.suhlasKontaktnyTelefon)) {
				suhlasy.push(
					this.prepareSuhlas({
						...suhlas,
						suhlasData: values.suhlasKontaktnyTelefon,
						definiciaId: DEFINICIA_TYP.KONTAKTNY_TELEFON
					})
				)
			}
		}
		if (!isEmpty(values?.suhlasKontaktnyEmail?.nesuhlasDovod) && values.suhlasKontaktnyEmail?.hodnota === SUHLAS_HODNOTA.NAMIETKA) {
			if (areDifferentNesuhlas(originalValues?.suhlasKontaktnyEmail, values.suhlasKontaktnyEmail)) {
				suhlasy.push(
					this.prepareSuhlas({
						...suhlas,
						suhlasData: values.suhlasKontaktnyEmail,
						definiciaId: DEFINICIA_TYP.KONTAKTY_EMAIL
					})
				)
			}
		}

		return suhlasy
	}

	stepOneClickHandler = async (values) => {
		const { interakcia, ukonyActions, ukonNovy, tracking, dispatch } = this.props

		this.setLoading(true)

		const body = this.formatDataForUkon(values)

		try {
			let ukonID
			// if ukon not exist yet create a new one else update it
			if (!get(ukonNovy, 'id')) {
				const res = await postReq(`/api/v2/op/${get(interakcia, 'opCislo')}/ukony`, null, body)
				// set podpis Miesto from response CP-588
				const podpisMiesto = get(res, 'response.content.podpisMiesto')
				dispatch(change(FORMS.OBCHODNY_PARTNER_EDITACIA, 'podpisMiesto', podpisMiesto))

				ukonID = get(res, 'response.content.id')
				ukonyActions.setUkonId(ukonID)
			} else {
				ukonID = get(ukonNovy, 'id')
				await putReq(`/api/v2/ukony/${ukonID}`, null, {
					...body,
					trvanie: dayjs().diff(dayjs(get(tracking, 'startTime')), 'millisecond'),
					id: ukonID
				})
			}

			// documents which are not uploaded does not have ID
			const notUploadedDocuments = filter(get(values, 'dokumenty', []), (document) => !get(document, 'id'))
			const uploadedDocuments = filter(get(values, 'dokumenty', []), (document) => get(document, 'id'))

			const documents = map(notUploadedDocuments, async (document) => {
				const documentResponse = await postReq(`/api/v0/ukony/${ukonID}/prilozit-dokument`, null, {
					contentType: get(document, 'type'),
					nazov: get(document, 'name'),
					data: get(document, 'dataAsBase64'),
					typ: {
						id: DOKUMENT_TYP.VSTUPNY
					}
				})
				return {
					type: get(documentResponse, 'response.contentType'),
					id: get(documentResponse, 'response.id'),
					dataAsBase64: get(document, 'dataAsBase64'),
					typ: get(documentResponse, 'response.typ'),
					name: get(documentResponse, 'response.nazov')
				}
			})

			const documentsPromises = await Promise.all(documents)

			// merge already uploaded documents and fresh uploaded documents
			dispatch(change(FORMS.OBCHODNY_PARTNER_EDITACIA, 'dokumenty', [...uploadedDocuments, ...documentsPromises]))

			this.setState({
				step: 2,
				isLoading: false
			})
		} catch (e) {
			this.setLoading(false)
			// eslint-disable-next-line
			console.log(e)
		}
	}

	stepTwoClickHandler = async (values) => {
		const { auth, ukonNovy, tracking, notification, templatePdf, signedPdf, t, dataActions, interakcia } = this.props
		const opCislo = get(interakcia, 'opCislo')
		const ukonId = get(ukonNovy, 'id')

		this.setLoading(true)

		const notifikacie = {
			adresyPosta: [],
			adresyUri: []
		}
		if (get(notification, 'typ') == NOTIFICATION_TYPES.ADDRESS) {
			notifikacie.adresyPosta.push(get(notification, 'address'))
		} else if (get(notification, 'typ') == NOTIFICATION_TYPES.EMAIL) {
			notifikacie.adresyUri.push({
				typ: NOTIFICATION_TYPES.EMAIL,
				hodnota: get(notification, 'email')
			})
		} else if (get(notification, 'typ') == NOTIFICATION_TYPES.PRINTER) {
			notifikacie.adresyUri.push({
				typ: NOTIFICATION_TYPES.PRINTER,
				poradie: 0
			})
		}

		const documentForUpload = get(signedPdf, 'data.dataAsBase64') ? get(signedPdf, 'data') : get(templatePdf, 'data')
		try {
			if (documentForUpload) {
				await postReq(`/api/v0/ukony/${ukonId}/prilozit-dokument`, null, {
					contentType: get(documentForUpload, 'type'),
					typ: {
						id: DOKUMENT_TYP.VYSTUPNY
					},
					nazov: get(documentForUpload, 'name'),
					data: get(documentForUpload, 'dataAsBase64')
				})
			}

			const promiseArray = []

			const body = this.formatDataForUkon(values)
			const startUkonPromise = postReq(`/api/v2/ukony/${ukonId}/spustit`, null, {
				...body,
				trvanie: dayjs().diff(dayjs(get(tracking, 'startTime')), 'millisecond'),
				notifikacie,
				id: ukonId
			})

			promiseArray.push(startUkonPromise)

			const suhlasy = this.formDataForSuhlasy({
				values,
				opCislo,
				auth,
				interakcia,
				ukonId
			})

			if (!isEmpty(suhlasy)) {
				const suhlasyPromise = postReq(`/api/ces/suhlasy/${opCislo}`, null, [...suhlasy])
				promiseArray.push(suhlasyPromise)
			}

			await Promise.all(promiseArray)

			dataActions.unregisterLeavePageModal()
			const message = 'Úkon bol úspešne odoslaný'
			this.setState({
				result: message,
				success: true,
				isLoading: false
			})
		} catch (e) {
			dataActions.unregisterLeavePageModal()
			this.setState({
				result: t('translation:Common.Počas odosielania úkonu nastala chyba'),
				success: false,
				isLoading: false
			})
		}
	}

	onDeleteFile = async (fileID) => {
		const { ukonNovy } = this.props

		try {
			await deleteReq(`/api/v0/ukony/${get(ukonNovy, 'id')}/dokumenty/${fileID}`)
		} catch (e) {
			/* eslint-disable no-console */
			console.log(e)
		}
	}

	confirmModal = () => {
		const { interakcia, ukonyActions, obchodnyPartnerActions, interakcieActions, selectedFiltersUkony } = this.props
		const { success } = this.state

		if (success) {
			this.setState(
				{
					result: null,
					success: false
				},
				() => {
					// refetch new data for historia ukonov
					ukonyActions.loadHistoriaUkonov(1, undefined, selectedFiltersUkony)

					// refetch new data for OP
					obchodnyPartnerActions.loadZmluvneUcty(true)
					obchodnyPartnerActions.loadObchodnyPartnerDetail(get(interakcia, 'opCislo'))

					// clear ukonId from storage
					ukonyActions.clearUkonId()

					interakcieActions.refreshAcw()

					history.replace(setRouteParams(OP_DETAIL, get(interakcia, 'opCislo')))
				}
			)
		} else {
			this.setState({
				result: null
			})
		}
	}

	checkAddresses = () => {
		const { formValues, addresses } = this.props
		const { originalValues } = this.state

		let globalZmenaAdresaZakaznika = true
		let globalZmenaAdresaKorespondencna = true
		// NOTE: also check for update of addresses
		const adresaZakaznika = formatAddressForResponse('adresaZakaznika', formValues, addresses)
		const adresaKorespondencna = formatAddressForResponse('adresaKorespondencna', formValues, addresses)

		if (
			!isEqual(formValues?.adresaZakaznika?.id, originalValues?.adresaZakaznika?.id) ||
			!isEqual(omit(formValues?.adresaZakaznika, 'globalnaZmena'), adresaZakaznika)
		) {
			globalZmenaAdresaZakaznika = false
		}
		if (
			!isEqual(formValues?.adresaKorespondencna?.id, originalValues?.adresaKorespondencna?.id) ||
			!isEqual(omit(formValues?.adresaKorespondencna, 'globalnaZmena'), adresaKorespondencna)
		) {
			globalZmenaAdresaKorespondencna = false
		}

		return {
			globalZmenaAdresaZakaznika,
			globalZmenaAdresaKorespondencna
		}
	}

	componentDidUpdate() {
		const { isDisabledGlobalZmenaAdresaZakaznika, isDisabledGlobalZmenaAdresaKorespondencna } = this.state

		const { globalZmenaAdresaZakaznika, globalZmenaAdresaKorespondencna } = this.checkAddresses()

		let newState = {}
		// NOTE: ak je hodnota isDisabledGlobalZmenaAdresaZakaznika false tak ignorujeme validaciu adresy zakaznika
		if (isDisabledGlobalZmenaAdresaZakaznika && isDisabledGlobalZmenaAdresaZakaznika !== globalZmenaAdresaZakaznika) {
			newState = {
				...newState,
				isDisabledGlobalZmenaAdresaZakaznika: globalZmenaAdresaZakaznika
			}
		}
		// NOTE: ak je hodnota isDisabledGlobalZmenaAdresaKorespondencna false tak ignorujeme validaciu adresy korespondencnej
		if (isDisabledGlobalZmenaAdresaKorespondencna && isDisabledGlobalZmenaAdresaKorespondencna !== globalZmenaAdresaKorespondencna) {
			newState = {
				...newState,
				isDisabledGlobalZmenaAdresaKorespondencna: globalZmenaAdresaKorespondencna
			}
		}

		if (!isEmpty(newState)) {
			this.setState({
				...newState
			})
		}
	}

	render() {
		const { interakcia, t, zmluvneUcty, aktivneZmluvneUcty, notification, signedPdf, ukonEditOp } = this.props

		const {
			step,
			result,
			success,
			isLoading,
			scenarioOption,
			originalValues,
			originalValuesInfo,
			isDisabledGlobalZmenaAdresaZakaznika,
			isDisabledGlobalZmenaAdresaKorespondencna
		} = this.state

		const { allDataPrepared, allDataLoading, isFailure } = zmluvneUcty

		if (
			!this._mounted ||
			isLoading ||
			!allDataPrepared ||
			allDataLoading ||
			get(ukonEditOp, 'isLoading') ||
			aktivneZmluvneUcty.allDataLoading ||
			!aktivneZmluvneUcty.allDataPrepared
		) {
			return <ElementLoading />
		}

		if (isFailure || aktivneZmluvneUcty.isFailure) {
			return <ElementFailure text={t('translation:ZmluvneUcty.Nepodarilo sa načítať zmluvné účty')} />
		}
		if (get(ukonEditOp, 'isFailure')) {
			return <ElementFailure text={t('translation:Common.Nepodarilo sa načítať context úkonu')} />
		}

		let modal = null
		if (result) {
			modal = (
				<DefaultModal
					modalTitle={success ? t('translation:Common.Odoslané') : t('translation:Common.Chyba')}
					modalContent={result}
					leftButton={{
						onClick: this.confirmModal,
						text: t('translation:Common.Zavrieť'),
						color: success ? 'green' : 'red'
					}}
					visible
				/>
			)
		}

		let component = null
		switch (step) {
			case 1:
				component = (
					<ObchodnyPartnerEditInfo
						{...this.props}
						originalValues={originalValuesInfo}
						formTitle={t('translation:ObchodnyPartner.Krok 1 z 2 Editácia údajov obchodného partnera')}
						onSubmit={this.stepOneClickHandler}
						onBackClick={() => history.push(setRouteParams(OP_DETAIL, get(interakcia, 'opCislo')))}
						onCancelClick={() => history.push(setRouteParams(OP_DETAIL, get(interakcia, 'opCislo')))}
						onDeleteFile={this.onDeleteFile}
						isDisabledGlobalZmenaAdresaZakaznika={isDisabledGlobalZmenaAdresaZakaznika}
						isDisabledGlobalZmenaAdresaKorespondencna={isDisabledGlobalZmenaAdresaKorespondencna}
					/>
				)
				break
			case 2: {
				// if notification is required and not selected yet
				const isDisabledNotification = get(scenarioOption, 'notificationRequired') && !get(notification, 'typ')

				// for scenarios VYTLACIT_A_NAHRAT and PODPISANIE_NA_MIESTE is required signedPDF
				let isDisabledSignedPdf = true
				if (
					(find(get(scenarioOption, 'scenarios', []), { typ: SCENARE.VYTLACIT_A_NAHRAT }) ||
						find(get(scenarioOption, 'scenarios', []), { typ: SCENARE.PODPISANIE_NA_MIESTE })) &&
					get(signedPdf, 'data.dataAsBase64')
				) {
					isDisabledSignedPdf = false
				} else if (
					find(get(scenarioOption, 'scenarios', []), { typ: SCENARE.ODOSLAT_BEZ_PODPISU }) ||
					find(get(scenarioOption, 'scenarios', []), { typ: SCENARE.ODOSLAT_NA_PODPIS })
				) {
					isDisabledSignedPdf = false
				}

				component = (
					<ObchodnyPartnerEditConfirm
						{...this.props}
						formTitle={t('translation:ObchodnyPartner.Krok 2 z 2 Editácia údajov obchodného partnera')}
						onSubmit={this.stepTwoClickHandler}
						onBackClick={() => {
							this.setState({ step: 1 })
						}}
						onCancelClick={() => history.push(setRouteParams(OP_DETAIL, get(interakcia, 'opCislo')))}
						originalValues={originalValues}
						scenarioOption={scenarioOption}
						isDisabledNotification={isDisabledNotification}
						isDisabledSignedPdf={isDisabledSignedPdf}
					/>
				)
				break
			}
			default:
		}
		return (
			<>
				{modal}
				{component}
			</>
		)
	}
}

const mapStateToProps = (state) => ({
	interakcia: get(state, 'interakcie.detail.data'),
	auth: get(state, 'auth'),
	obchodnyPartner: get(state, 'obchodnyPartner.detail.data'),
	zmluvneUcty: get(state, 'obchodnyPartner.zmluvneUcty'),
	aktivneZmluvneUcty: get(state, 'obchodnyPartner.aktivneZmluvneUcty'),
	ciselniky: get(state, 'ciselniky.data'),
	tracking: get(state, 'tracking'),
	addresses: get(state, 'formAddresses.data'),
	ukonNovy: get(state, 'ukony.ukonNovy'),
	signedPdf: get(state, 'podpisovanieDokumentov.signedPdf'),
	notification: get(state, 'podpisovanieDokumentov.notification'),
	templatePdf: get(state, 'podpisovanieDokumentov.templatePdf'),
	ukonEditOp: get(state, 'ukonEditOp.obchodnyPartner'),
	formValues: getFormValues(FORMS.OBCHODNY_PARTNER_EDITACIA)(state),
	selectedFiltersUkony: get(state, `selectedFilters.${FILTER_SELECTORS.SIDEBAR_HISTORY}`, {})
})

const mapDispatchToProps = (dispatch) => ({
	dispatch,
	trackingActions: bindActionCreators(TrackingActions, dispatch),
	ukonyActions: bindActionCreators(UkonyActions, dispatch),
	obchodnyPartnerActions: bindActionCreators(ObchodnyPartnerActions, dispatch),
	formAddressesActions: bindActionCreators(FormAddresssesActions, dispatch),
	dataActions: bindActionCreators(DataActions, dispatch),
	ukonEditOpActions: bindActionCreators(UkonEditOpActions, dispatch),
	interakcieActions: bindActionCreators(InterakcieActions, dispatch)
})

export default compose(
	withTranslation('containers'),
	connect(mapStateToProps, mapDispatchToProps),
	withPermissions([PERMISSIONS.UKON_UDAJE_OP])
)(ObchodnyPartnerEditPage)
