import React from 'react'
import { compose, bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { get, set, head, filter, map, find, includes, isEmpty, maxBy, split, forEach, some } from 'lodash'
import { initialize, reset, destroy, change, touch, getFormValues } from 'redux-form'
import dayjs from 'dayjs'
import qs from 'qs'
import reactQueryParams from 'react-query-params'
import PropTypes from 'prop-types'

// components
import PlanSplatokDedicSearch from '../../components/PlanySplatok/PlanSplatokCreateForm/PlanSplatokDedicSearch/PlanSplatokDedicSearch'
import PlanSplatokSearch from '../../components/PlanySplatok/PlanSplatokCreateForm/PlanSplatokSearch/PlanSplatokSearch'
import PlanSplatokInfo from '../../components/PlanySplatok/PlanSplatokCreateForm/PlanSplatokInfo/PlanSplatokInfo'
import PlanSplatokConfirm from '../../components/PlanySplatok/PlanSplatokCreateForm/PlanSplatokConfirm'
import DefaultModal from '../../components/Modals/DefaultModal'
import ElementLoading from '../../components/ElementLoading'
import ElementEmptyContent from '../../components/ElementEmptyContent'
import VyberTypuPlatcuPlanuSplatok from '../../components/Modals/VyberTypuPlatcuPlanuSplatok'
import initProcesnyKonfigurator from '../../components/ProcesnyKonfigurator'

// actions
import * as TrackingActions from '../../actions/TrackingActions'
import * as UkonyActions from '../../actions/UkonyActions'
import * as CiselnikySelectBoxActions from '../../actions/CiselnikySelectBoxActions'
import PodpisovanieDokumentovActions from '../../actions/PodpisovanieDokumentov'
import * as DataActions from '../../actions/DataActions'
import * as FiltersActions from '../../actions/SelectedFiltersActions'
import * as StatusActions from '../../actions/StatusActions'
import * as OtvoreneFakturyActions from '../../actions/ObchodniPartneri/OtvoreneFakturyActions'
import * as PlanySplatokActions from '../../actions/ObchodniPartneri/PlanySplatokActions'
import * as UkonPlanSplatokContextActions from '../../actions/ProcesnyKonfigurator/UkonPlanSplatokContextActions'
import * as FormAddresssesActions from '../../actions/FormAddresses'
import * as SearchActions from '../../actions/SearchActions'

// utils
import { postReq, deleteReq, putReq } from '../../utils/request'
import {
	BUSINESS_CHANNELS,
	SCENARE,
	NOTIFICATION_TYPES,
	FORMS,
	UKONY,
	UKONY_CISELNIK,
	TYP_PLATCU_PLANU_SPLATOK,
	OBCHODNY_PARTNER_DRUH,
	UKON_STAV,
	FILTER_SELECTORS,
	DOKUMENT_TYP
} from '../../utils/enums'
import { setRouteParams, PLANY_SPLATOK_ZOZNAM } from '../../utils/routes'
import { history } from '../../utils/history'
import { createFormInitValues } from '../../utils/form'
import { getUkonVstupIdByBiznisKanal } from '../../utils/scenar'
import { withPermissions, checkPermissions, PERMISSIONS } from '../../utils/permissionsHoc'
import { formatAddressForResponse } from '../../utils/address'
import { celeMeno } from '../../utils/obchodnyPartner'

const scenarioOptions = [
	{
		kanal: BUSINESS_CHANNELS.ZSE_CENTRUM,
		scenarios: [{ typ: SCENARE.PODPISANIE_NA_MIESTE }, { typ: SCENARE.VYTLACIT_A_NAHRAT }, { typ: SCENARE.NAHRAT }],
		additionalNotification: {
			required: true,
			rules: {
				types: [NOTIFICATION_TYPES.PRINTER, NOTIFICATION_TYPES.VOID_NOTIFICATION]
			},
			defaultType: NOTIFICATION_TYPES.PRINTER,
			types: [NOTIFICATION_TYPES.EMAIL, NOTIFICATION_TYPES.ADDRESS]
		},
		notificationRequired: true,
		documentRequired: true,
		notificationTypes: [NOTIFICATION_TYPES.EMAIL, NOTIFICATION_TYPES.ADDRESS, NOTIFICATION_TYPES.PRINTER, NOTIFICATION_TYPES.VOID_NOTIFICATION]
	},
	{
		kanal: BUSINESS_CHANNELS.ZAKAZNICKA_LINKA,
		scenarios: [{ typ: SCENARE.ODOSLAT_BEZ_PODPISU }],
		additionalNotification: {
			required: true,
			rules: {
				types: [NOTIFICATION_TYPES.VOID_NOTIFICATION]
			},
			defaultType: NOTIFICATION_TYPES.VOID_NOTIFICATION,
			types: [NOTIFICATION_TYPES.EMAIL, NOTIFICATION_TYPES.ADDRESS]
		},
		notificationRequired: true,
		documentRequired: false,
		notificationTypes: [NOTIFICATION_TYPES.EMAIL, NOTIFICATION_TYPES.ADDRESS, NOTIFICATION_TYPES.VOID_NOTIFICATION]
	},
	{
		kanal: BUSINESS_CHANNELS.BACK_OFFICE,
		scenarios: [{ typ: SCENARE.ODOSLAT_BEZ_PODPISU }],
		additionalNotification: {
			required: true,
			rules: {
				types: [NOTIFICATION_TYPES.VOID_NOTIFICATION]
			},
			defaultType: NOTIFICATION_TYPES.VOID_NOTIFICATION,
			types: [NOTIFICATION_TYPES.EMAIL, NOTIFICATION_TYPES.ADDRESS]
		},
		notificationRequired: true,
		documentRequired: false,
		notificationTypes: [NOTIFICATION_TYPES.EMAIL, NOTIFICATION_TYPES.ADDRESS, NOTIFICATION_TYPES.VOID_NOTIFICATION]
	}
]

const queryParams = {
	platcaTyp: 'platcaTyp',
	splnomocnenec: 'splnomocnenec',
	backUrl: 'backUrl'
}

class PlanSplatokCreatePage extends reactQueryParams {
	static propTypes = {
		dispatch: PropTypes.func.isRequired,
		interakcia: PropTypes.shape({
			id: PropTypes.any.isRequired
		}).isRequired,
		auth: PropTypes.shape().isRequired,
		trackingActions: PropTypes.shape().isRequired,
		ukonyActions: PropTypes.shape({
			clearUkonId: PropTypes.func.isRequired
		}).isRequired,
		ciselnikySelectBoxActions: PropTypes.shape().isRequired,
		podpisovanieDokumentovActions: PropTypes.shape({
			resetTemplatePdf: PropTypes.func.isRequired
		}).isRequired,
		dataActions: PropTypes.shape().isRequired,
		filtersActions: PropTypes.shape().isRequired,
		statusActions: PropTypes.shape().isRequired,
		otvoreneFakturyActions: PropTypes.shape().isRequired,
		planySplatokActions: PropTypes.shape().isRequired,
		ukonPlanSplatokPKContextActions: PropTypes.shape().isRequired,
		searchActions: PropTypes.shape().isRequired,
		tracking: PropTypes.shape().isRequired,
		ukonNovy: PropTypes.shape().isRequired,
		ciselniky: PropTypes.shape().isRequired,
		ciselnikySelectBox: PropTypes.shape().isRequired,
		signedPdf: PropTypes.shape(),
		notification: PropTypes.shape(),
		templatePdf: PropTypes.shape(),
		procesnyKonfigurator: PropTypes.shape({
			data: PropTypes.shape(),
			isLoading: PropTypes.bool.isRequired,
			isFailure: PropTypes.bool.isRequired
		}).isRequired,
		ukonPlanSplatokPKContext: PropTypes.shape({
			data: PropTypes.shape(),
			isLoading: PropTypes.bool.isRequired,
			isFailure: PropTypes.bool.isRequired
		}).isRequired,
		statuses: PropTypes.arrayOf(PropTypes.shape()).isRequired,
		splnomocnenia: PropTypes.shape(),
		t: PropTypes.func.isRequired
	}

	defaultQueryParams = {
		[queryParams.platcaTyp]: null,
		[queryParams.splnomocnenec]: null,
		[queryParams.backUrl]: null
	}

	_mounted = false

	constructor(props) {
		super(props)

		const ProcesnyKonfigurator = initProcesnyKonfigurator(FORMS.PLANY_SPLATOK_VYTVORENIE, 'planSplatok')

		// filter possible scenars for signing pdf files
		const scenarioOption = filter(scenarioOptions, (scenarioOption) => {
			return scenarioOption.kanal == get(props, 'auth.businessChannel.actual.id')
		})

		this.state = {
			step: 1,
			scenarioOption: head(scenarioOption),
			isLoading: true,
			ProcesnyKonfigurator,
			koKriteria: [],
			isKoKriteriumChecked: false,
			validacneKriteria: [],
			schvalovacieKriteria: [],
			context: null
		}
	}

	componentDidMount = async () => {
		this._mounted = true

		const {
			dispatch,
			auth,
			trackingActions,
			dataActions,
			ciselnikySelectBoxActions,
			otvoreneFakturyActions,
			formAddressesActions,
			searchActions,
			tracking,
			ciselniky,
			splnomocnenia
		} = this.props

		let { platcaTyp, splnomocnenec, backUrl } = qs.parse(window.location.search, {
			ignoreQueryPrefix: true
		})

		let splnomocnenie
		if (splnomocnenec) {
			const [cisloSplnomocnenca, platnostOd] = split(splnomocnenec, ' ')
			splnomocnenie = find(get(splnomocnenia, 'data', []), (splnomocnenie) => {
				return get(splnomocnenie, 'op2.cislo') == cisloSplnomocnenca && get(splnomocnenie, 'platnostOd') == platnostOd
			})
		}

		if (!platcaTyp) {
			if (get(auth, 'businessChannel.actual.id') == BUSINESS_CHANNELS.ZAKAZNICKA_LINKA) {
				platcaTyp = TYP_PLATCU_PLANU_SPLATOK.SAM_ZA_SEBA
			} else {
				this.setState({
					vyberTypuPlatcuPlanuSplatok: {
						showModal: true
					}
				})
			}
		} else if (platcaTyp == TYP_PLATCU_PLANU_SPLATOK.SPLNOMOCNENEC && !splnomocnenie) {
			this.setState({
				vyberTypuPlatcuPlanuSplatok: {
					showModal: true
				}
			})
		}

		let backUrlLink
		if (backUrl) {
			backUrlLink = atob(backUrl)
		}

		// NOTE: clear backUrl query param from url just esthetic reason, do not use history.replace or similar clearing cause NavigationPrompt issue
		this.setQueryParams({
			[queryParams.platcaTyp]: this.defaultQueryParams[queryParams.platcaTyp],
			[queryParams.splnomocnenec]: this.defaultQueryParams[queryParams.splnomocnenec],
			[queryParams.backUrl]: this.defaultQueryParams[queryParams.backUrl]
		})

		this.setState({
			backUrlLink,
			step: platcaTyp == TYP_PLATCU_PLANU_SPLATOK.DEDIC ? 0 : 1
		})

		searchActions.searchUsersClear()
		otvoreneFakturyActions.fakturyClear()
		ciselnikySelectBoxActions.loadCiselnikZmluvneUcty()
		dataActions.registerLeavePageModal()

		if (!tracking.startTime || (tracking.startTime && tracking.type !== UKONY.PLAN_SPLATOK)) {
			trackingActions.tryToStartTracking(UKONY.PLAN_SPLATOK)
		}

		formAddressesActions.formAddressClean()

		const initOPValues = createFormInitValues({
			/* NOTE: CP-1234 typ: null,
			druh: null, */
			mode: 'EXISTING_OP',
			selectedOP: null,
			oslovenie: null,
			titulPred1: null,
			titulPred2: null,
			titulZa: null,
			meno: null,
			priezvisko: null,
			telefonneCislo: null,
			kontaktnyEmail: null,
			narodenieDatum: null,
			adresaZakaznika: null,
			adresaKorespondencna: null
		})

		dispatch(initialize(FORMS.PLANY_SPLATOK_ZALOZENIE_OP, initOPValues, true))
		dispatch(reset(FORMS.PLANY_SPLATOK_ZALOZENIE_OP))

		// trigger validation immediately after init form
		/* NOTE: CP-1234 dispatch(change(FORMS.PLANY_SPLATOK_ZALOZENIE_OP, 'druh', null, true)) */
		dispatch(change(FORMS.PLANY_SPLATOK_ZALOZENIE_OP, 'oslovenie', null, true))
		dispatch(change(FORMS.PLANY_SPLATOK_ZALOZENIE_OP, 'meno', null, true))
		dispatch(change(FORMS.PLANY_SPLATOK_ZALOZENIE_OP, 'priezvisko', null, true))
		dispatch(change(FORMS.PLANY_SPLATOK_ZALOZENIE_OP, 'narodenieDatum', null, true))
		dispatch(change(FORMS.PLANY_SPLATOK_ZALOZENIE_OP, 'adresaZakaznika.id', null, true))

		const initOtvoreneFakturyValues = createFormInitValues({
			zuCislo: null,
			zdrojoveFaktury: []
		})

		dispatch(initialize(FORMS.PLANY_SPLATOK_VYTVORENIE_VYBER_FAKTUR, initOtvoreneFakturyValues, true))
		dispatch(reset(FORMS.PLANY_SPLATOK_VYTVORENIE_VYBER_FAKTUR))

		const initValues = createFormInitValues({
			ukonVstup: getUkonVstupIdByBiznisKanal(get(auth, 'businessChannel.actual.id'), get(ciselniky, 'ukonVstup')),
			datumPrijatiaZiadosti: new Date(),
			podpisMiesto: get(auth, 'user.podpisMiesto', ''),
			splnomocnenec: celeMeno(get(splnomocnenie, 'op2')),
			splnomocnenie,
			dedic: null,
			zuCislo: null,
			zdrojoveFaktury: [],
			splatkyPocet: null,
			datumSplatnosti: null,
			urok: true,
			skontrolovaneZalohy: null,
			platcaTyp
		})

		dispatch(initialize(FORMS.PLANY_SPLATOK_VYTVORENIE, initValues, true))
		dispatch(reset(FORMS.PLANY_SPLATOK_VYTVORENIE))

		if (!get(initValues, 'ukonVstup')) {
			// trigger validation immediately after init form
			dispatch(touch(FORMS.PLANY_SPLATOK_VYTVORENIE, 'ukonVstup'))
		}
		if (get(auth, 'businessChannel.actual.id') == BUSINESS_CHANNELS.BACK_OFFICE && isEmpty(get(initValues, 'dokumenty'))) {
			// trigger validation immediately after init form
			dispatch(touch(FORMS.PLANY_SPLATOK_VYTVORENIE, 'dokumenty'))
		}

		dispatch(touch(FORMS.PLANY_SPLATOK_VYTVORENIE, 'splatkyPocet'))
		dispatch(touch(FORMS.PLANY_SPLATOK_VYTVORENIE, 'datumSplatnosti'))
		dispatch(touch(FORMS.PLANY_SPLATOK_VYTVORENIE, 'skontrolovaneZalohy'))

		this.setState({
			isLoading: false
		})
	}

	componentWillUnmount = () => {
		const { dispatch, trackingActions, ukonyActions, dataActions, otvoreneFakturyActions, planySplatokActions } = this.props

		dispatch(destroy(FORMS.PLANY_SPLATOK_VYTVORENIE))
		dispatch(destroy(FORMS.PLANY_SPLATOK_VYTVORENIE_VYBER_FAKTUR))
		dispatch(destroy(FORMS.PLANY_SPLATOK_ZALOZENIE_OP))

		otvoreneFakturyActions.fakturyClear()
		dataActions.unregisterLeavePageModal()
		trackingActions.clearTracking()
		planySplatokActions.aktualneNastavenieClear()

		// clear ukonId from storage
		ukonyActions.clearUkonId()

		this._mounted = false
	}

	componentDidUpdate = (prevProps) => {
		const { vyberFakturFormValues } = this.props
		if (!get(vyberFakturFormValues, 'zuCislo') && get(prevProps, 'vyberFakturFormValues.zuCislo')) {
			this.setState({
				context: null
			})
		}
	}

	formatDataForUkon = (values) => {
		const { interakcia, auth, tracking, ciselniky, planSplatokAktualneNastavenie } = this.props

		const { schvalovacieKriteria } = this.state

		let typ
		let platcaOp

		switch (get(values, 'platcaTyp')) {
			case TYP_PLATCU_PLANU_SPLATOK.SAM_ZA_SEBA: {
				typ = find(get(ciselniky, 'ukonTyp', []), (ukonTyp) => get(ukonTyp, 'id') == UKONY_CISELNIK.PLAN_SPLATOK)
				break
			}
			case TYP_PLATCU_PLANU_SPLATOK.SPLNOMOCNENEC: {
				typ = find(get(ciselniky, 'ukonTyp', []), (ukonTyp) => get(ukonTyp, 'id') == UKONY_CISELNIK.PLAN_SPLATOK_SPLNOMOCNENEC)
				platcaOp = {
					cislo: get(values, 'splnomocnenie.op2.cislo')
				}
				break
			}
			case TYP_PLATCU_PLANU_SPLATOK.DEDIC: {
				typ = find(get(ciselniky, 'ukonTyp', []), (ukonTyp) => get(ukonTyp, 'id') == UKONY_CISELNIK.PLAN_SPLATOK_DEDIC)
				platcaOp =
					get(values, 'platcaOpCislo') === -1
						? this.formatOpForUkon(values)
						: {
								cislo: get(values, 'platcaOpCislo')
						  }
				break
			}
		}
		const vstup = find(get(ciselniky, 'ukonVstup', []), (vstup) => get(vstup, 'id') == get(values, 'ukonVstup'))

		let schvalovaciRezim = null
		if (!isEmpty(schvalovacieKriteria)) {
			const maxUrovenSchvalovania = get(
				maxBy(
					filter(schvalovacieKriteria, (schvalovacieKriterium) => !schvalovacieKriterium.vysledok),
					'urovenSchvalovania'
				),
				'urovenSchvalovania',
				0
			)
			const schvalovacieKriteriaNormalize = map(schvalovacieKriteria, (schvalovacieKriterium) => ({
				id: schvalovacieKriterium.nazov,
				vysledok: schvalovacieKriterium.vysledok,
				dovod: schvalovacieKriterium.popis
			}))
			schvalovaciRezim = {
				kod: maxUrovenSchvalovania,
				pravidla: schvalovacieKriteriaNormalize
			}
		}

		return {
			typ,
			opCislo: get(interakcia, 'opCislo'),
			interakciaId: get(interakcia, 'id'),
			riesitel: get(auth, 'user.id'),
			kanal: get(auth, 'businessChannel.actual'),
			trvanie: dayjs().diff(dayjs(tracking.startTime), 'millisecond'),
			zacatyOd: dayjs(tracking.startTime).toISOString(),
			vstup,
			podpisMiesto: get(values, 'podpisMiesto'),
			poznamka: get(values, 'poznamka'),
			ziadanyOd: dayjs(get(values, 'datumPrijatiaZiadosti')).toISOString(),
			splnomocnenec: get(values, 'splnomocnenec'),
			poznamkaInterna: get(values, 'poznamkaInterna'),
			data: {
				schvalovaciRezim,
				zuCislo: get(values, 'zuCislo'),
				zahrnuteFaktury: get(values, 'zdrojoveFaktury', []),
				splatkyPocet: +get(values, 'splatkyPocet'),
				datumSplatnosti: get(values, 'datumSplatnosti') ? dayjs(get(values, 'datumSplatnosti')).format('YYYY-MM-DD') : null,
				urok: get(values, 'urok', true),
				skontrolovaneZalohy: get(values, 'skontrolovaneZalohy'),
				platcaTyp: get(values, 'platcaTyp', null),
				platcaOp,
				kalkulovanePolozky: get(planSplatokAktualneNastavenie, 'data', [])
			}
		}
	}

	formatOpForUkon = (values) => {
		const { ciselniky, addresses, dedicFormValues } = this.props

		const tituly = []
		forEach(get(ciselniky, 'data.akademickyTitulHodnota', []), (titul) => {
			if (get(dedicFormValues, 'titulPred1') && get(titul, 'id') == get(dedicFormValues, 'titulPred1')) {
				tituly.push({
					poradie: 1,
					hodnota: titul
				})
			}
			if (get(dedicFormValues, 'titulPred2') && get(titul, 'id') == get(dedicFormValues, 'titulPred2')) {
				tituly.push({
					poradie: 2,
					hodnota: titul
				})
			}
			if (get(dedicFormValues, 'titulZa') && get(titul, 'id') == get(dedicFormValues, 'titulZa')) {
				tituly.push({
					poradie: 1,
					hodnota: titul
				})
			}
		})

		const druh = find(get(ciselniky, 'data.obchodnyPartnerDruh', []), (item) => {
			return get(item, 'id') == OBCHODNY_PARTNER_DRUH.DOMACNOST
		})

		const oslovenie = find(get(ciselniky, 'data.obchodnyPartnerOslovenie', []), (item) => {
			return get(item, 'id') == get(dedicFormValues, 'oslovenie')
		})

		const adresaZakaznika = formatAddressForResponse('adresaZakaznika', dedicFormValues, addresses)
		let adresaKorespondencna
		if (get(dedicFormValues, 'adresaZakaznika.globalnaZmena')) {
			adresaKorespondencna = adresaZakaznika
		} else {
			adresaKorespondencna = formatAddressForResponse('adresaKorespondencna', dedicFormValues, addresses)
		}

		return {
			cislo: get(values, 'platcaOpCislo'),
			typ: 'MOO', // NOTE: CP-1234
			druh,
			oslovenie,
			tituly,
			meno: get(dedicFormValues, 'meno'),
			priezvisko: get(dedicFormValues, 'priezvisko'),
			narodenieDatum: get(dedicFormValues, 'narodenieDatum') ? dayjs(get(dedicFormValues, 'narodenieDatum')).format('YYYY-MM-DD') : '',
			kontaktnyTelefon: get(dedicFormValues, 'telefonneCislo') || '',
			kontaktnyEmail: get(dedicFormValues, 'kontaktnyEmail') || '',
			adresaZakaznika,
			adresaKorespondencna
		}
	}

	setLoading = (value) => {
		if (this._mounted) {
			this.setState({
				isLoading: value
			})
		}
	}

	onDeleteFile = async (fileID) => {
		const { ukonNovy } = this.props

		try {
			await deleteReq(`/api/v0/ukony/${ukonNovy.id}/dokumenty/${fileID}`)
		} catch (e) {
			/* eslint-disable no-console */
			console.log(e)
		}
	}

	stepZeroClickHandler = async (values) => {
		const { dispatch, ciselniky, t } = this.props

		let platcaOpCislo
		let dedic

		if (get(values, 'selectedOP')) {
			platcaOpCislo = get(values, 'selectedOP')
			dedic = `${platcaOpCislo || '0'} - ${get(values, 'splnomocnenaOsoba')}`
		} else {
			platcaOpCislo = -1 // NOTE: pre noveho OP sa nastavuje zaporna hodnota

			const titulPred1 = find(get(ciselniky, 'data.akademickyTitulHodnota', []), (titul) => get(titul, 'id') == get(values, 'titulPred1'))
			const titulPred2 = find(get(ciselniky, 'data.akademickyTitulHodnota', []), (titul) => get(titul, 'id') == get(values, 'titulPred2'))
			const titulZa = find(get(ciselniky, 'data.akademickyTitulHodnota', []), (titul) => get(titul, 'id') == get(values, 'titulZa'))

			dedic =
				[
					t('translation:Common.Nový OP'),
					'-',
					get(titulPred1, 'nazov', ''),
					get(titulPred2, 'nazov', ''),
					get(values, 'meno', ''),
					get(values, 'priezvisko', ''),
					get(titulZa, 'nazov', '')
				]
					.join(' ')
					.trim() || '-'
		}

		dispatch(change(FORMS.PLANY_SPLATOK_VYTVORENIE, 'platcaOpCislo', platcaOpCislo))
		dispatch(change(FORMS.PLANY_SPLATOK_VYTVORENIE, 'dedic', dedic))

		if (this._mounted) {
			this.setState({
				step: 1
			})
		}
	}

	stepOneClickHandler = async (values) => {
		const { dispatch, ukonPlanSplatokPKContextActions } = this.props

		try {
			this.setLoading(true)

			const context = await ukonPlanSplatokPKContextActions.loadContext({
				zuCislo: get(values, 'zuCislo')
			})

			dispatch(change(FORMS.PLANY_SPLATOK_VYTVORENIE, 'zuCislo', get(values, 'zuCislo')))
			dispatch(change(FORMS.PLANY_SPLATOK_VYTVORENIE, 'zdrojoveFaktury', get(values, 'zdrojoveFaktury')))

			if (this._mounted) {
				this.setState({
					context,
					step: 2,
					isLoading: false
				})
			}
		} catch (e) {
			this.setLoading(false)
			// eslint-disable-next-line
			console.log(e)
		}
	}

	stepTwoClickHandler = async (values) => {
		const { dispatch, interakcia, ukonyActions, tracking, ukonNovy } = this.props

		this.setLoading(true)

		const body = this.formatDataForUkon(values)

		try {
			let ukonId
			// if ukon not exist yet create a new one else update it
			if (!ukonNovy.id) {
				const res = await postReq(`/api/v2/op/${get(interakcia, 'opCislo')}/ukony`, null, body)

				// set podpis Miesto from response CP-588
				const podpisMiesto = get(res, 'response.content.podpisMiesto')
				dispatch(change(FORMS.PLANY_SPLATOK_VYTVORENIE, 'podpisMiesto', podpisMiesto))

				ukonId = get(res, 'response.content.id')
				ukonyActions.setUkonId(ukonId)
			} else {
				ukonId = ukonNovy.id
				const updateBody = {
					...body,
					trvanie: dayjs().diff(dayjs(tracking.startTime), 'millisecond'),
					id: ukonId
				}
				await putReq(`/api/v2/ukony/${ukonId}`, null, updateBody)
			}

			// dokumenty which are not upload does not have ID
			const notUploadedDokumenty = filter(get(values, 'dokumenty', []), (dokument) => !get(dokument, 'id'))
			const uploadedDokumenty = filter(get(values, 'dokumenty', []), (dokument) => get(dokument, 'id'))

			const dokumenty = map(notUploadedDokumenty, async (file) => {
				const dokumentResponse = await postReq(`/api/v0/ukony/${ukonId}/prilozit-dokument`, null, {
					contentType: file.type,
					nazov: file.name,
					data: file.dataAsBase64,
					typ: {
						id: DOKUMENT_TYP.VSTUPNY
					}
				})
				return {
					type: get(dokumentResponse, 'response.contentType'),
					id: get(dokumentResponse, 'response.id'),
					dataAsBase64: get(file, 'dataAsBase64'),
					typ: get(dokumentResponse, 'response.typ'),
					name: get(dokumentResponse, 'response.nazov')
				}
			})

			const dokumentyPromises = await Promise.all(dokumenty)

			// merge already uploaded dokumenty and fresh uploaded
			dispatch(change(FORMS.PLANY_SPLATOK_VYTVORENIE, 'dokumenty', [...uploadedDokumenty, ...dokumentyPromises]))

			if (this._mounted) {
				this.setState({
					step: 3,
					isLoading: false
				})
			}
		} catch (e) {
			this.setLoading(false)
			// eslint-disable-next-line
			console.log(e)
		}
	}

	stepThreeClickHandler = async (values) => {
		const { tracking, dataActions, ukonNovy, signedPdf, notification, additionalNotification, templatePdf, t } = this.props

		this.setLoading(true)

		const notifikacie = {
			adresyPosta: [],
			adresyUri: []
		}
		if (get(notification, 'typ') == NOTIFICATION_TYPES.ADDRESS) {
			notifikacie.adresyPosta.push(get(notification, 'address'))
		} else if (get(notification, 'typ') == NOTIFICATION_TYPES.EMAIL) {
			notifikacie.adresyUri.push({
				typ: NOTIFICATION_TYPES.EMAIL,
				hodnota: get(notification, 'email')
			})
		} else if (get(notification, 'typ') == NOTIFICATION_TYPES.PRINTER) {
			notifikacie.adresyUri.push({
				typ: NOTIFICATION_TYPES.PRINTER,
				poradie: 0
			})
		}

		if (!additionalNotification) {
			set(notifikacie, 'adresyStav', {
				[UKON_STAV.SPRACOVANY]: {
					...notifikacie
				},
				[UKON_STAV.STORNOVANY]: {
					...notifikacie
				}
			})
		} else if (get(additionalNotification, 'typ') == NOTIFICATION_TYPES.ADDRESS) {
			set(notifikacie, 'adresyStav', {
				[UKON_STAV.SPRACOVANY]: {
					adresyPosta: [get(additionalNotification, 'address')]
				},
				[UKON_STAV.STORNOVANY]: {
					adresyPosta: [get(additionalNotification, 'address')]
				}
			})
		} else if (get(additionalNotification, 'typ') == NOTIFICATION_TYPES.EMAIL) {
			set(notifikacie, 'adresyStav', {
				[UKON_STAV.SPRACOVANY]: {
					adresyUri: [
						{
							typ: NOTIFICATION_TYPES.EMAIL,
							hodnota: get(additionalNotification, 'email')
						}
					]
				},
				[UKON_STAV.STORNOVANY]: {
					adresyUri: [
						{
							typ: NOTIFICATION_TYPES.EMAIL,
							hodnota: get(additionalNotification, 'email')
						}
					]
				}
			})
		}
		const documentForUpload = get(signedPdf, 'data.dataAsBase64') ? get(signedPdf, 'data') : get(templatePdf, 'data')
		try {
			if (documentForUpload) {
				await postReq(`/api/v0/ukony/${ukonNovy.id}/prilozit-dokument`, null, {
					contentType: get(documentForUpload, 'type'),
					typ: {
						id: DOKUMENT_TYP.VYSTUPNY
					},
					nazov: get(documentForUpload, 'name'),
					data: get(documentForUpload, 'dataAsBase64')
				})
			}

			const body = this.formatDataForUkon(values)

			const spustitBody = {
				...body,
				trvanie: dayjs().diff(dayjs(tracking.startTime), 'millisecond'),
				notifikacie,
				id: ukonNovy.id
			}
			await postReq(`/api/v2/ukony/${ukonNovy.id}/spustit`, null, spustitBody)

			if (this._mounted) {
				dataActions.unregisterLeavePageModal()
				this.setState({
					result: t('translation:Common.Úkon bol úspešne odoslaný'),
					success: true,
					isLoading: false
				})
			}
		} catch (e) {
			if (this._mounted) {
				dataActions.unregisterLeavePageModal()
				this.setState({
					result: t('translation:Common.Počas odosielania úkonu nastala chyba'),
					success: false,
					isLoading: false
				})
			}
		}
	}

	confirmModal = () => {
		const { interakcia, ukonyActions, selectedFiltersUkony } = this.props
		const { success } = this.state

		if (this._mounted) {
			if (success) {
				this.setState(
					{
						success: false
					},
					() => {
						// clear ukonId from storage
						ukonyActions.clearUkonId()
						// refetch new data for historia ukonov
						ukonyActions.loadHistoriaUkonov(1, undefined, selectedFiltersUkony)
						history.replace(setRouteParams(PLANY_SPLATOK_ZOZNAM, get(interakcia, 'opCislo')))
					}
				)
			} else {
				this.setState({
					result: null
				})
			}
		}
	}

	koKriteriaHandler = (isKoKriteriumChecked, koKriteria) => {
		this.setState({
			isKoKriteriumChecked,
			koKriteria
		})
	}

	validacneKriteriaHandler = (validacneKriteria) =>
		this.setState({
			validacneKriteria
		})

	schvalovacieKriteriaHandler = (schvalovacieKriteria) =>
		this.setState({
			schvalovacieKriteria
		})

	setStep = (step) => this.setState({ step })

	render = () => {
		const { interakcia, dispatch, auth, signedPdf, notification, additionalNotification, procesnyKonfigurator, splnomocnenia, planySplatokFormValues, t } =
			this.props
		const {
			step,
			scenarioOption,
			isLoading,
			koKriteria,
			validacneKriteria,
			schvalovacieKriteria,
			context,
			success,
			result,
			dissallowedScenarios,
			backUrlLink,
			vyberTypuPlatcuPlanuSplatok,
			ProcesnyKonfigurator
		} = this.state

		if (isLoading || get(procesnyKonfigurator, 'isLoading')) {
			return <ElementLoading />
		}

		if (get(procesnyKonfigurator, 'isFailure') || isEmpty(procesnyKonfigurator, 'data.ukony')) {
			return <ElementEmptyContent text={t('translation:ProcesnyKonfigurator.Ľutujeme ale nepodarilo sa načítať Procesný konfigurátor')} />
		}

		let modal
		if (get(vyberTypuPlatcuPlanuSplatok, 'showModal')) {
			modal = (
				<VyberTypuPlatcuPlanuSplatok
					modalTitle={t('translation:PlanySplatok.Zvoľte typ platcu pre vytvorenie plánu splátok')}
					onCloseButton={() => {
						history.push(`${setRouteParams(PLANY_SPLATOK_ZOZNAM, get(interakcia, 'opCislo'))}`)
					}}
					onSubmit={async (context) => {
						const platcaTyp = get(context, 'selectedPlatcaTyp')
						await dispatch(change(FORMS.PLANY_SPLATOK_VYTVORENIE, 'platcaTyp', platcaTyp))
						if (platcaTyp == TYP_PLATCU_PLANU_SPLATOK.SPLNOMOCNENEC) {
							let splnomocnenie
							if (get(context, 'selectedSplnomocnenec')) {
								const [cisloSplnomocnenca, platnostOd] = split(decodeURIComponent(get(context, 'selectedSplnomocnenec')), '+')
								splnomocnenie = find(get(splnomocnenia, 'data', []), (splnomocnenie) => {
									return get(splnomocnenie, 'op2.cislo') == cisloSplnomocnenca && get(splnomocnenie, 'platnostOd') == platnostOd
								})
							}
							await dispatch(change(FORMS.PLANY_SPLATOK_VYTVORENIE, 'splnomocnenec', celeMeno(get(splnomocnenie, 'op2'))))
							await dispatch(change(FORMS.PLANY_SPLATOK_VYTVORENIE, 'splnomocnenie', splnomocnenie))
						}
						this.setState({
							vyberTypuPlatcuPlanuSplatok: {
								showModal: false
							},
							step: platcaTyp == TYP_PLATCU_PLANU_SPLATOK.DEDIC ? 0 : 1
						})
					}}
				/>
			)
		}

		// check if every Ko kriterium pass condition, if not navigate user back to step 1
		let nextStep = step
		const invalidKoKriteria = filter(koKriteria, {
			vysledok: false
		})
		if (!isEmpty(invalidKoKriteria)) {
			nextStep = 1
		}

		let component
		if (!modal) {
			switch (nextStep) {
				case 0:
					component = (
						<PlanSplatokDedicSearch
							{...this.props}
							formTitle={t('translation:PlanySplatok.Krok 1 z 4 Vytvorenie plánu splátok')}
							onSubmit={this.stepZeroClickHandler}
							onBackClick={() => {
								history.push(backUrlLink || setRouteParams(PLANY_SPLATOK_ZOZNAM, get(interakcia, 'opCislo')))
							}}
							schvalovacieKriteria={schvalovacieKriteria}
							validacneKriteria={validacneKriteria}
						/>
					)
					break
				case 1:
					component = (
						<PlanSplatokSearch
							{...this.props}
							formTitle={
								get(planySplatokFormValues, 'platcaTyp') == TYP_PLATCU_PLANU_SPLATOK.DEDIC
									? t('translation:PlanySplatok.Krok 2 zo 4 Vytvorenie plánu splátok')
									: t('translation:PlanySplatok.Krok 1 z 3 Vytvorenie plánu splátok')
							}
							onSubmit={this.stepOneClickHandler}
							onBackClick={() => {
								get(planySplatokFormValues, 'platcaTyp') == TYP_PLATCU_PLANU_SPLATOK.DEDIC
									? this.setState({ step: 0 })
									: history.push(backUrlLink || setRouteParams(PLANY_SPLATOK_ZOZNAM, get(interakcia, 'opCislo')))
							}}
							koKriteria={koKriteria}
							schvalovacieKriteria={schvalovacieKriteria}
							validacneKriteria={validacneKriteria}
							/* koKriteriaHandler={this.koKriteriaHandler} */
							setStep={this.setStep}
						/>
					)
					break
				case 2:
					component = (
						<PlanSplatokInfo
							{...this.props}
							formTitle={
								get(planySplatokFormValues, 'platcaTyp') == TYP_PLATCU_PLANU_SPLATOK.DEDIC
									? t('translation:PlanySplatok.Krok 3 zo 4 Vytvorenie plánu splátok')
									: t('translation:PlanySplatok.Krok 2 z 3 Vytvorenie plánu splátok')
							}
							onSubmit={this.stepTwoClickHandler}
							onDeleteFile={this.onDeleteFile}
							onBackClick={() => {
								this.setState({ step: 1 })
							}}
							schvalovacieKriteria={schvalovacieKriteria}
							validacneKriteria={validacneKriteria}
						/>
					)
					break
				case 3: {
					// check if scenar is define
					const customizedScenarioOption = {
						...scenarioOption,
						scenarios: filter(get(scenarioOption, 'scenarios', []), (scenar) => {
							return (
								!includes(dissallowedScenarios, get(scenar, 'typ')) &&
								isEmpty(get(scenar, 'allowedRoles', []) || checkPermissions(get(auth, 'user.roles', []), get(scenar, 'allowedRoles', [])))
							)
						})
					}

					// if notification is required and not selected yet
					const isDisabledNotification =
						(get(customizedScenarioOption, 'notificationRequired') && !get(notification, 'typ')) ||
						(get(customizedScenarioOption, 'additionalNotification.required') &&
							!get(additionalNotification, 'typ') &&
							some(schvalovacieKriteria, (schvalovacieKriterium) => !get(schvalovacieKriterium, 'vysledok')) &&
							includes(get(scenarioOption, 'additionalNotification.rules.types', []), get(notification, 'typ')))

					// for scenarios VYTLACIT_A_NAHRAT and PODPISANIE_NA_MIESTE is required signedPDF
					let isDisabledSignedPdf = true
					if (
						(find(get(customizedScenarioOption, 'scenarios', []), { typ: SCENARE.VYTLACIT_A_NAHRAT }) ||
							find(get(customizedScenarioOption, 'scenarios', []), { typ: SCENARE.PODPISANIE_NA_MIESTE })) &&
						get(signedPdf, 'data.dataAsBase64')
					) {
						isDisabledSignedPdf = false
					} else if (
						find(get(customizedScenarioOption, 'scenarios', []), { typ: SCENARE.ODOSLAT_BEZ_PODPISU }) ||
						find(get(customizedScenarioOption, 'scenarios', []), { typ: SCENARE.ODOSLAT_NA_PODPIS })
					) {
						isDisabledSignedPdf = false
					}

					const isDisabledScenarNotSelected =
						get(customizedScenarioOption, 'scenarios', []).length > get(customizedScenarioOption, 'maxAllowedScenars')
					component = (
						<PlanSplatokConfirm
							{...this.props}
							formTitle={
								get(planySplatokFormValues, 'platcaTyp') == TYP_PLATCU_PLANU_SPLATOK.DEDIC
									? t('translation:PlanySplatok.Krok 4 zo 4 Vytvorenie plánu splátok')
									: t('translation:PlanySplatok.Krok 3 z 3 Vytvorenie plánu splátok')
							}
							scenarioOption={customizedScenarioOption}
							onSubmit={this.stepThreeClickHandler}
							onBackClick={() => {
								this.setState({ step: 2 })
							}}
							notification={notification}
							isDisabledNotification={isDisabledNotification}
							isDisabledSignedPdf={isDisabledSignedPdf}
							isDisabledScenarNotSelected={isDisabledScenarNotSelected}
							schvalovacieKriteria={schvalovacieKriteria}
							disallowScenarios={this.disallowScenarios}
						/>
					)
					break
				}
				default:
			}
		}

		return (
			<>
				{result && (
					<DefaultModal
						modalTitle={success ? t('translation:Common.Odoslané') : t('translation:Common.Chyba')}
						modalContent={result}
						leftButton={{
							onClick: this.confirmModal,
							text: t('translation:Common.Zavrieť'),
							color: success ? 'green' : 'red'
						}}
						visible
					/>
				)}
				{component}
				{modal}
				<ProcesnyKonfigurator
					koKriteriaHandler={this.koKriteriaHandler}
					validacneKriteriaHandler={this.validacneKriteriaHandler}
					schvalovacieKriteriaHandler={this.schvalovacieKriteriaHandler}
					formatDataForUkon={this.formatDataForUkon}
					context={context}
				/>
			</>
		)
	}

	disallowScenarios = (scenarios) =>
		this.setState({
			disallowedScenarios: scenarios
		})
}

const mapStateToProps = (state) => ({
	interakcia: get(state, 'interakcie.detail.data'),
	auth: get(state, 'auth'),
	tracking: get(state, 'tracking'),
	ukonNovy: get(state, 'ukony.ukonNovy'),
	ciselniky: get(state, 'ciselniky.data'),
	ciselnikySelectBox: get(state, 'ciselnikySelectBox'),
	signedPdf: get(state, 'podpisovanieDokumentov.signedPdf'),
	notification: get(state, 'podpisovanieDokumentov.notification'),
	additionalNotification: get(state, 'podpisovanieDokumentov.additionalNotification'),
	templatePdf: get(state, 'podpisovanieDokumentov.templatePdf'),
	procesnyKonfigurator: get(state, 'procesnyKonfigurator.procesnyKonfigurator'),
	ukonPlanSplatokPKContext: get(state, 'ukonPlanSplatokPKContext.context'),
	statuses: get(state, 'statuses.statuses'),
	otvoreneFaktury: get(state, 'otvoreneFaktury.otvoreneFaktury'),
	planSplatokAktualneNastavenie: get(state, 'planySplatok.planSplatokAktualneNastavenie'),
	splnomocnenia: get(state, 'obchodnyPartner.splnomocnenia'),
	addresses: get(state, 'formAddresses.data'),
	dedicFormValues: getFormValues(FORMS.PLANY_SPLATOK_ZALOZENIE_OP)(state),
	vyberFakturFormValues: getFormValues(FORMS.PLANY_SPLATOK_VYTVORENIE_VYBER_FAKTUR)(state),
	planySplatokFormValues: getFormValues(FORMS.PLANY_SPLATOK_VYTVORENIE)(state),
	selectedFiltersUkony: get(state, `selectedFilters.${FILTER_SELECTORS.SIDEBAR_HISTORY}`, {})
})

const mapDispatchToProps = (dispatch) => ({
	dispatch,
	trackingActions: bindActionCreators(TrackingActions, dispatch),
	ukonyActions: bindActionCreators(UkonyActions, dispatch),
	ciselnikySelectBoxActions: bindActionCreators(CiselnikySelectBoxActions, dispatch),
	podpisovanieDokumentovActions: bindActionCreators(PodpisovanieDokumentovActions, dispatch),
	dataActions: bindActionCreators(DataActions, dispatch),
	filtersActions: bindActionCreators(FiltersActions, dispatch),
	statusActions: bindActionCreators(StatusActions, dispatch),
	otvoreneFakturyActions: bindActionCreators(OtvoreneFakturyActions, dispatch),
	planySplatokActions: bindActionCreators(PlanySplatokActions, dispatch),
	ukonPlanSplatokPKContextActions: bindActionCreators(UkonPlanSplatokContextActions, dispatch),
	searchActions: bindActionCreators(SearchActions, dispatch),
	formAddressesActions: bindActionCreators(FormAddresssesActions, dispatch)
})

export default compose(
	withTranslation('containers'),
	connect(mapStateToProps, mapDispatchToProps),
	withPermissions([PERMISSIONS.UKON_PLAN_SPLATOK])
)(PlanSplatokCreatePage)
