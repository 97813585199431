import { get, values, filter, size } from 'lodash'

import {
	HISTORIA_UKONOV_LOAD_START,
	HISTORIA_UKONOV_LOAD_FAIL,
	HISTORIA_UKONOV_LOAD_DONE,
	UKONY_BY_INTERAKCIA_ID_LOAD_DONE,
	UKONY_BY_INTERAKCIA_ID_LOAD_FAIL,
	UKONY_BY_INTERAKCIA_ID_LOAD_START,
	UKON_SET_ID,
	UKON_CLEAR_ID,
	UKONY_ODBERNE_MIESTO_LOAD_START,
	UKONY_ODBERNE_MIESTO_LOAD_FAIL,
	UKONY_ODBERNE_MIESTO_LOAD_DONE,
	UKONY_ODBERNE_MIESTO_CLEAR
} from '../types/ukony'
import { HISTORIA_UKONOV_PAGE_SIZE, UKON_STAV } from '../utils/enums'
import { getReq } from '../utils/request'

const initStateOdberneMiesto = {
	data: null,
	isLoaded: false,
	isLoading: false,
	isFailure: false
}

export const loadHistoriaUkonov = (page, pageSize = HISTORIA_UKONOV_PAGE_SIZE, queryParams = {}) => {
	return async (dispatch, getStore) => {
		const store = getStore()
		const interakcia = get(store, 'interakcie.detail.data')

		dispatch({
			type: HISTORIA_UKONOV_LOAD_START,
			payload: {
				page,
				pageSize
			}
		})

		const query = {
			...queryParams,
			opCislo: get(interakcia, 'opCislo'),
			pageSize,
			page,
			sort: 'vytvorenyOd:DESC'
		}

		try {
			const ukonyData = await getReq(`/api/v2/ukony`, {
				...query,
				stavId: values(UKON_STAV).join(',')
			})

			const ukony = get(ukonyData, 'response.content', [])

			ukony.sort((ukonA) => (ukonA.ukonStav.id === UKON_STAV.ODLOZENY ? -1 : 0))

			const isLastPage = size(ukony) < pageSize

			dispatch({
				type: HISTORIA_UKONOV_LOAD_DONE,
				payload: {
					historiaUkonov: ukony,
					isLastPage
				}
			})
		} catch (e) {
			dispatch({
				type: HISTORIA_UKONOV_LOAD_FAIL
			})
		}
	}
}

export const loadUkonyByInteractionId = () => {
	return async (dispatch, getStore) => {
		const store = getStore()
		const interakcia = get(store, 'interakcie.detail.data')

		dispatch({
			type: UKONY_BY_INTERAKCIA_ID_LOAD_START
		})

		const query = {
			opCislo: get(interakcia, 'opCislo'),
			interakciaId: `${get(interakcia, 'id')}`,
			pageSize: 100,
			page: 1,
			sort: 'vytvorenyOd:DESC'
		}

		try {
			const ukonyData = await getReq(`/api/v2/ukony`, query)
			const ukony = get(ukonyData, 'response.content', [])

			dispatch({
				type: UKONY_BY_INTERAKCIA_ID_LOAD_DONE,
				payload: {
					ukonyByInterakciaId: ukony
				}
			})
		} catch (e) {
			dispatch({
				type: UKONY_BY_INTERAKCIA_ID_LOAD_FAIL
			})
		}
	}
}

export const loadUkonyForOdberneMiesto = (omCislo, identifikator, queryParams = {}) => {
	return async (dispatch) => {
		dispatch({
			type: UKONY_ODBERNE_MIESTO_LOAD_START,
			payload: {
				odberneMiesto: {
					[omCislo]: {
						...initStateOdberneMiesto,
						isLoading: true
					}
				}
			}
		})

		const query = {
			...queryParams,
			objektTyp: 'OdberneMiestoDTO',
			objektId: identifikator || omCislo,
			sort: 'vytvorenyOd:DESC'
		}
		try {
			const ukonyData = await getReq('/api/v2/ukony', query)
			const ukony = get(ukonyData, 'response.content', [])

			dispatch({
				type: UKONY_ODBERNE_MIESTO_LOAD_DONE,
				payload: {
					odberneMiesto: {
						[omCislo]: {
							...initStateOdberneMiesto,
							data: ukony,
							isLoaded: true,
							isLoading: false
						}
					}
				}
			})

			return ukony
		} catch (e) {
			dispatch({
				type: UKONY_ODBERNE_MIESTO_LOAD_FAIL,
				payload: {
					odberneMiesto: {
						[omCislo]: {
							...initStateOdberneMiesto,
							isFailure: true
						}
					}
				}
			})
		}
	}
}

export const clearUkonyForOdberneMiesto = (omCislo) => {
	return async (dispatch) => {
		dispatch({
			type: UKONY_ODBERNE_MIESTO_CLEAR,
			payload: {
				odberneMiesto: {
					[omCislo]: undefined
				}
			}
		})
	}
}

export const setUkonId = (ukonId) => {
	return (dispatch) => {
		dispatch({
			type: UKON_SET_ID,
			payload: { ukonId }
		})
	}
}

export const clearUkonId = () => {
	return (dispatch) => {
		dispatch({
			type: UKON_CLEAR_ID
		})
	}
}
