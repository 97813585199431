import React from 'react'
import { connect } from 'react-redux'
import { Field, touch } from 'redux-form'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'

import { get, map, size, values, indexOf } from 'lodash'

// atoms
import { CheckboxField } from '../../../atoms'

// config
import { COLUMNS_COUNT, EDIT_MODE } from '../../../containers/GenericUkon/genericUkonConfig'

class UcelPouzitiaGasField extends React.Component {
	static propTypes = {
		dispatch: PropTypes.func.isRequired,
		field: PropTypes.string.isRequired,
		form: PropTypes.string.isRequired,
		editMode: PropTypes.string.isRequired,
		columnsCount: PropTypes.number,
		povinny: PropTypes.bool.isRequired,
		t: PropTypes.func.isRequired,
		value: PropTypes.shape(),
		originalValue: PropTypes.shape(),
		change: PropTypes.func.isRequired
	}

	state = {}

	constructor(props) {
		super(props)
	}

	componentDidMount() {
		this._mounted = true

		const { dispatch, field, form, editMode } = this.props

		if (editMode == EDIT_MODE.EDIT || editMode == EDIT_MODE.LOOKUP) {
			dispatch(touch(form, field))
		}
	}

	componentWillUnmount() {
		this._mounted = false
	}

	formatUcelPouzitia = (value, originalValue) => {
		const { t } = this.props

		const temp = []
		if (get(value, 'varenie')) {
			if (originalValue && get(originalValue, 'varenie') != get(value, 'varenie')) {
				temp.push(<strong>{t('translation:Common.Varenie')}</strong>)
			} else {
				temp.push(t('translation:Common.Varenie'))
			}
		}
		if (get(value, 'vykurovanie')) {
			if (originalValue && get(originalValue, 'vykurovanie') != get(value, 'vykurovanie')) {
				temp.push(<strong>{t('translation:Common.Vykurovanie')}</strong>)
			} else {
				temp.push(t('translation:Common.Vykurovanie'))
			}
		}
		if (get(value, 'tuv')) {
			if (originalValue && get(originalValue, 'tuv') != get(value, 'tuv')) {
				temp.push(<strong>{t('translation:Common.TÚV')}</strong>)
			} else {
				temp.push(t('translation:Common.TÚV'))
			}
		}

		const tempSize = size(temp)
		return map(temp, (item, index) => {
			return (
				<>
					{item}
					{tempSize > 0 && index < tempSize - 1 && ', '}
				</>
			)
		})
	}

	handleChange = (selectedValue, key) => {
		const { dispatch, change, form, field, value } = this.props

		dispatch(
			change(field, {
				...(value || {}),
				[key]: selectedValue
			})
		)
		dispatch(touch(form, field))
	}

	validate = (fieldValue) => {
		const { povinny, t } = this.props

		if (povinny && indexOf(values(fieldValue), true) < 0) {
			return t('translation:Common.validate.Vyberte aspoň jednu hodnotu')
		}
	}

	render() {
		const { field, editMode, columnsCount = COLUMNS_COUNT.THREE, value, originalValue, t } = this.props

		const valueBefore = this.formatUcelPouzitia(originalValue)
		let valueAfter = null

		let errors

		if (editMode == EDIT_MODE.EDIT || editMode == EDIT_MODE.LOOKUP) {
			errors = this.validate(value)

			valueAfter = (
				<>
					<Field name={field} validate={this.validate} component='input' type='hidden' />
					<table>
						<tbody>
							<tr data-name={field}>
								<td>
									<CheckboxField
										input={{
											value: get(value, 'varenie') || false,
											onChange: (selectedValue) => this.handleChange(selectedValue, 'varenie')
										}}
										label={t('translation:Common.Varenie')}
									/>
								</td>
								<td>
									<CheckboxField
										input={{
											value: get(value, 'vykurovanie') || false,
											onChange: (selectedValue) => this.handleChange(selectedValue, 'vykurovanie')
										}}
										label={t('translation:Common.Vykurovanie')}
									/>
								</td>
								<td>
									<CheckboxField
										input={{
											value: get(value, 'tuv') || false,
											onChange: (selectedValue) => this.handleChange(selectedValue, 'tuv')
										}}
										label={t('translation:Common.TÚV')}
									/>
								</td>
							</tr>
							{errors && (
								<tr>
									<td colSpan={3} style={{ paddingTop: 0, paddingBottom: 0 }}>
										<div className='text-danger'>{errors}</div>
									</td>
								</tr>
							)}
						</tbody>
					</table>
				</>
			)
		} else if (editMode == EDIT_MODE.CONFIRM || editMode == EDIT_MODE.DETAIL) {
			valueAfter = this.formatUcelPouzitia(value, value)
		}

		return (
			<div className='inner-box'>
				<table className='content-table padded bordered' cellSpacing='0'>
					<tbody>
						<tr>
							<td>
								<strong>{t('translation:Common.Použitie plynu')}</strong>
							</td>
							{columnsCount == COLUMNS_COUNT.THREE && <td>{valueBefore}</td>}
							<td>{valueAfter}</td>
						</tr>
					</tbody>
				</table>
			</div>
		)
	}
}

const mapDispatchToProps = (dispatch) => ({
	dispatch
})

export default compose(withTranslation('components'), connect(null, mapDispatchToProps))(UcelPouzitiaGasField)
