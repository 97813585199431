import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Field, reduxForm, propTypes, formValueSelector, change, touch, untouch, submit } from 'redux-form'
import { withTranslation } from 'react-i18next'
import { map, get, find, filter, orderBy, forEach, replace, lowerCase, includes, uniqBy } from 'lodash'
import cx from 'classnames'
import { compose } from 'redux'

import * as IBAN from 'iban'

// model
import { searchOptionsStat } from '../AddressField/AddressFieldModel'

// atoms
import validate from './validateForm'
import { AsyncSelectField, TextInputField, BasicSelect } from '..'

// resources
import addPlusIcon from '../../resources/img/icons/add-plus.svg'

// components
import Modal from '../../components/Modals/Modal'

class IBANField extends React.Component {
	static propTypes = {
		...propTypes,
		dispatch: PropTypes.func.isRequired,
		ciselniky: PropTypes.shape(),
		label: PropTypes.string,
		isDisabled: PropTypes.bool,
		isCreatable: PropTypes.bool,
		isClearable: PropTypes.bool,
		isActiveEditMode: PropTypes.bool.isRequired,
		options: PropTypes.arrayOf(PropTypes.string).isRequired,
		selectedOption: PropTypes.shape({
			value: PropTypes.string,
			label: PropTypes.string
		}),
		formFields: PropTypes.arrayOf(
			PropTypes.shape({
				name: PropTypes.string
			})
		).isRequired,
		formValues: PropTypes.shape({
			prefix: PropTypes.string,
			number: PropTypes.string,
			countryCode: PropTypes.string,
			bankCode: PropTypes.string,
			countryCodeHelper: PropTypes.string,
			iban: PropTypes.string
		}).isRequired,
		onSelectedOptionChange: PropTypes.func.isRequired,
		onVisibilityChange: PropTypes.func.isRequired,
		handleSubmit: PropTypes.func.isRequired,
		invalid: PropTypes.bool,
		t: PropTypes.func.isRequired
	}

	state = {
		statyOptions: [],
		bankyOptions: [],
		isCountrySetByIBAN: false
	}

	componentDidMount() {
		const { ciselniky, dispatch, form } = this.props

		let bankyOptionsAll = map(get(ciselniky, 'banka'), (banka) => {
			let label = get(banka, 'kod', '')
			if (get(banka, 'nazov')) {
				label += label ? ` - ${get(banka, 'nazov')}` : get(banka, 'nazov')
			}
			if (get(banka, 'swiftKod')) {
				label += label ? ` (${get(banka, 'swiftKod')})` : get(banka, 'swiftKod')
			}
			return {
				label,
				value: get(banka, 'kod', null),
				countryCode: get(banka, 'stat', null)
			}
		})

		const uniqBankyByCountry = map(uniqBy(bankyOptionsAll, 'countryCode'), (banka) => get(banka, 'countryCode'))
		// filter only that counties which have at least one bank
		const statyOptions = filter(get(ciselniky, 'staty'), (stat) => includes(uniqBankyByCountry, get(stat, 'statKod')))

		bankyOptionsAll = orderBy(bankyOptionsAll, ['value'], ['asc'])

		// set SK as default country
		dispatch(change(form, 'countryCode', 'SK'))

		this.setState({
			statyOptions,
			bankyOptionsAll,
			bankyOptions: filter(bankyOptionsAll, (option) => get(option, 'countryCode') == 'SK')
		})
	}

	componentDidUpdate(prevProps) {
		const { dispatch, isActiveEditMode, form, formFields, formValues } = this.props
		const { bankyOptionsAll, isCountrySetByIBAN } = this.state

		const originalFormValues = get(prevProps, 'formValues')

		if (get(formValues, 'iban') != get(originalFormValues, 'iban')) {
			const countryCodeFromIban = get(formValues, 'iban')?.substring(0, 2)
			if (countryCodeFromIban) {
				dispatch(change(form, 'countryCode', countryCodeFromIban))
				this.setCountryCodeHelper(countryCodeFromIban)
				this.setState({
					isCountrySetByIBAN: true
				})
			}
		}

		if (get(formValues, 'prefix') != get(originalFormValues, 'prefix')) {
			const iban = this.composeIBAN(formValues)
			dispatch(change(form, 'iban', iban))
			dispatch(touch(form, 'iban'))
		}

		if (get(formValues, 'number') != get(originalFormValues, 'number')) {
			const iban = this.composeIBAN(formValues)
			dispatch(change(form, 'iban', iban))
			dispatch(touch(form, 'iban'))
		}

		if (get(formValues, 'countryCode') != get(originalFormValues, 'countryCode') && !isCountrySetByIBAN) {
			const iban = this.composeIBAN(formValues)
			dispatch(change(form, 'iban', iban))
			dispatch(touch(form, 'iban'))

			this.setCountryCodeHelper(get(formValues, 'countryCode'))

			this.setState({
				bankyOptions: get(formValues, 'countryCode')
					? filter(bankyOptionsAll, (option) => get(option, 'countryCode') == get(formValues, 'countryCode'))
					: bankyOptionsAll
			})
		}

		if (get(formValues, 'bankCode') != get(originalFormValues, 'bankCode')) {
			const iban = this.composeIBAN(formValues)
			dispatch(change(form, 'iban', iban))
			dispatch(touch(form, 'iban'))
		}

		if (isActiveEditMode == false && get(prevProps, 'isActiveEditMode') == true) {
			forEach(formFields, (field) => {
				dispatch(change(form, field.name, null))
				dispatch(untouch(form, field.name))
			})
			dispatch(change(form, 'countryCodeHelper', null))
			dispatch(change(form, 'countryCode', 'SK'))
		}
	}

	setCountryCodeHelper = (countryCode) => {
		const { dispatch, ciselniky, form, t } = this.props
		const countryIBAN = IBAN.countries[countryCode]
		const country = find(get(ciselniky, 'staty', []), (stat) => stat.statKod == countryCode)
		const countryCodeHelper =
			countryIBAN && country && get(country, 'statNazov')
				? t('atoms:IBANField.Poznámka Dĺžka čísla účtu v tvare IBAN pre krajinu {{statNazov}} je {{length}} znakov', {
						statNazov: get(country, 'statNazov'),
						length: get(countryIBAN, 'length')
				  })
				: null
		dispatch(change(form, 'countryCodeHelper', countryCodeHelper))
	}

	composeIBAN = (formValues) => {
		try {
			const prefix = get(formValues, 'prefix') || ''
			const number = get(formValues, 'number')
			const countryCode = get(formValues, 'countryCode')
			const bankCode = get(formValues, 'bankCode')
			if (!number || !countryCode || !bankCode) {
				return null
			}
			const countryIBAN = IBAN.countries[countryCode]
			if (!countryIBAN) {
				return null
			}
			let bban = `${bankCode}${prefix}`
			const missing0 = get(countryIBAN, 'length', 0) - get(bankCode, 'length', 0) - get(prefix, 'length', 0) - get(number, 'length', 0) - 4
			if (missing0 > 0) {
				for (let i = 0; i <= missing0 - 1; i++) {
					bban += '0'
				}
			}
			bban += `${number}`
			return IBAN.fromBBAN(countryCode, bban) || null
		} catch (err) {
			return null
		}
	}

	render() {
		const {
			form,
			dispatch,
			label,
			isDisabled,
			isCreatable,
			isClearable,
			isActiveEditMode,
			options,
			selectedOption,
			formValues,
			onSelectedOptionChange,
			onVisibilityChange,
			handleSubmit,
			invalid,
			t
		} = this.props
		const { statyOptions, bankyOptions, bankyOptionsAll } = this.state

		let editForm = null

		if (isActiveEditMode) {
			editForm = (
				<Modal size='m' shown>
					<form onSubmit={handleSubmit}>
						<div className='content-wrapper'>
							<div className='modal-header'>
								<h3>{t('atoms:IBANField.Pridať bankové spojenie')}</h3>
							</div>
							<div className='modal-content'>
								<div className='row' style={{ marginBottom: '20px' }}>
									<div className='col-6'>
										<Field
											name='countryCode'
											component={AsyncSelectField}
											placeholder={t('atoms:IBANField.Kód krajiny')}
											selectOptions={statyOptions}
											loadOptions={searchOptionsStat.bind(this)}
											onChange={() => {
												this.setState({
													isCountrySetByIBAN: false
												})
											}}
										/>
									</div>
									<div className='col-6'>
										<Field
											name='bankCode'
											component={AsyncSelectField}
											placeholder={t('atoms:IBANField.Kód banky')}
											selectOptions={bankyOptions}
											loadOptions={async (value) => {
												return filter(bankyOptionsAll, (option) => {
													return (
														includes(lowerCase(option.label), lowerCase(value)) &&
														option.countryCode == get(formValues, 'countryCode')
													)
												})
											}}
											isDisabled={!get(formValues, 'countryCode')}
										/>
									</div>
								</div>
								<div className='row' style={{ marginBottom: '20px' }}>
									<div className='col-6'>
										<Field name='prefix' component={TextInputField} placeholder={t('atoms:IBANField.Predčíslie účtu')} />
									</div>
									<div className='col-6'>
										<Field name='number' component={TextInputField} placeholder={t('atoms:IBANField.Číslo účtu')} />
									</div>
								</div>
								<div className='row'>
									<div className='col-12' style={{ marginBottom: '10px', fontSize: '12px', fontWeight: '600' }}>
										{t('atoms:IBANField.Číslo účtu v tvare IBAN')}
									</div>
									<div className='col-12'>
										<Field
											name='iban'
											component={TextInputField}
											format={(value) => (value ? IBAN.printFormat(value) : '')}
											placeholder={t('atoms:IBANField.IBAN')}
										/>
									</div>
									{get(formValues, 'countryCodeHelper') && (
										<div className='col-12' style={{ margin: '10px 0', fontSize: '12px', fontWeight: '400' }}>
											{get(formValues, 'countryCodeHelper')}
										</div>
									)}
								</div>
							</div>
							<div className='modal-footer clearfix'>
								<button
									className={cx('button', 'pull-right', { disabled: invalid })}
									type='button'
									onClick={() => dispatch(submit(form))}
									data-color='green'
								>
									{t('atoms:IBANField.Uložiť')}
								</button>
								<button className={`button pull-right`} onClick={() => onVisibilityChange(false)} data-type='outline' data-color='red'>
									{t('atoms:IBANField.Zrušiť')}
								</button>
							</div>
						</div>
					</form>
				</Modal>
			)
		}

		return (
			<>
				<div className='address-field clearfix'>
					<div>
						<BasicSelect
							label={label}
							value={selectedOption}
							options={options}
							isSearchable={false}
							isDisabled={isDisabled}
							isClearable={isClearable}
							onChange={onSelectedOptionChange}
							placeholder={t('atoms:IBANField.Vyberte bankové spojenie')}
							classNamePrefix='react-select'
						/>
					</div>
					{isCreatable && (
						<div className='address-field-add'>
							<img className='address-field-icon' onClick={() => onVisibilityChange(true)} src={addPlusIcon} />
						</div>
					)}
				</div>
				{!isDisabled && editForm}
			</>
		)
	}
}

const form = reduxForm({
	destroyOnUnmount: false,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	validate
})(IBANField)

const IBANFieldForm = withTranslation('atoms')(
	connect(
		(state, ownProps) => {
			const selector = formValueSelector(ownProps.form)
			return {
				ciselniky: state.ciselniky.data,
				formFields: get(state, `form['${ownProps.form}'].registeredFields`, []),
				formValues: {
					prefix: selector(state, 'prefix'),
					number: selector(state, 'number'),
					countryCode: selector(state, 'countryCode'),
					bankCode: selector(state, 'bankCode'),
					iban: selector(state, 'iban'),
					countryCodeHelper: selector(state, 'countryCodeHelper')
				}
			}
		},
		(dispatch) => ({ dispatch })
	)(form)
)

class IBANFieldWrapper extends React.Component {
	static propTypes = {
		dispatch: PropTypes.func.isRequired,
		formName: PropTypes.string.isRequired,
		input: PropTypes.shape({
			name: PropTypes.string,
			value: PropTypes.string,
			onChange: PropTypes.func.isRequired
		}).isRequired,
		meta: PropTypes.shape({
			touched: PropTypes.bool,
			error: PropTypes.string
		}).isRequired,
		accounts: PropTypes.arrayOf(PropTypes.string).isRequired,
		onNewAccount: PropTypes.func
	}

	constructor(props) {
		super(props)

		this.state = {
			options: [],
			isActiveEditMode: false
		}
	}

	componentDidMount() {
		const { accounts } = this.props

		const options = map(accounts, (account) => {
			const iban = get(account, 'IBAN') || ''
			return {
				value: replace(iban, /\s/g, ''),
				label: IBAN.printFormat(iban)
			}
		})

		this.setState({
			options
		})
	}

	componentDidUpdate(prevProps) {
		const { accounts } = this.props

		if (get(accounts, 'length') != get(prevProps, 'accounts.length')) {
			const options = map(accounts, (account) => {
				const iban = get(account, 'IBAN') || ''
				return {
					value: replace(iban, /\s/g, ''),
					label: IBAN.printFormat(iban)
				}
			})
			this.setState({ options })
		}
	}

	onSelectedOptionChange = (selectedOption) => {
		const { input } = this.props

		const onChange = get(input, 'onChange', null)
		if (onChange) {
			onChange(
				selectedOption
					? {
							id: selectedOption.value,
							IBAN: selectedOption.value
					  }
					: null
			)
		}
		this.setState({
			isActiveEditMode: false
		})
	}

	onSubmit = (formValues) => {
		const { onNewAccount } = this.props
		const { options } = this.state
		const iban = replace(get(formValues, 'iban', ''), /\s/g, '')

		const existing = find(options, (option) => option.value == iban)
		if (existing) {
			this.onSelectedOptionChange(existing)
		} else {
			this.onSelectedOptionChange({
				value: iban,
				label: IBAN.printFormat(iban)
			})
			if (onNewAccount) {
				onNewAccount(iban)
			}
		}
	}

	render() {
		const {
			formName,
			meta: { touched, error },
			input
		} = this.props
		const { options, isActiveEditMode } = this.state

		let value = null
		if (get(input, 'value.id')) {
			value = {
				value: replace(get(input, 'value.id', ''), /\s/g, ''),
				label: IBAN.printFormat(get(input, 'value.id') || '')
			}
		}

		return (
			<div data-name={input?.name} className={cx('select-wrapper', { 'has-error': touched && error })}>
				<IBANFieldForm
					isActiveEditMode={isActiveEditMode}
					onSelectedOptionChange={this.onSelectedOptionChange}
					onSubmit={this.onSubmit}
					onVisibilityChange={(isActiveEditMode) => {
						this.setState({ isActiveEditMode })
					}}
					form={formName}
					{...this.props}
					options={options}
					selectedOption={value}
				/>
				<div className='text-danger'>{touched ? error : ''}</div>
			</div>
		)
	}
}

export default compose(connect(null, (dispatch) => ({ dispatch })))(IBANFieldWrapper)
