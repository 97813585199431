import * as SigningPdf from './SigningPdf'
import * as ReadPdf from './ReadPdf'
import * as Notifikacie from './Notifikacie'
import * as TemplatePdf from './TemplatePdf'

export default {
	...SigningPdf,
	...ReadPdf,
	...Notifikacie,
	...TemplatePdf
}
