import axios from 'axios'
import { get } from 'lodash'
import { fi } from 'date-fns/locale'
import { READ_PDF_DONE, READ_PDF_CLEAR, READ_PDF_START, READ_PDF_CANCEL, READ_PDF_FAIL } from '../../types/podpisovanieDokumentov'
import { statusPushCreator } from '../StatusActions'
import { ERROR } from '../../utils/enums'

import config from '../../utils/config'

process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0'

let source
let checkPdfStateInterval = null

const wacomAxios = axios.create({
	baseURL: config.wacomUrl,
	timeout: 15000
	// TODO: CARE-XXX Check for solution
	// httpsAgent: new https.Agent({
	//	rejectUnauthorized: false
	// })
})

export const readFileDone = (data) => {
	return {
		type: READ_PDF_DONE,
		payload: {
			data
		}
	}
}

export const readFileFail = () => {
	return {
		type: READ_PDF_FAIL
	}
}

export const readFileCancel = () => {
	return {
		type: READ_PDF_CANCEL
	}
}

export const readFileStart = () => {
	return {
		type: READ_PDF_START
	}
}

export const clearReadFile = () => {
	return (dispatch) => {
		dispatch({
			type: READ_PDF_CLEAR
		})
	}
}

function handleError(error) {
	const status = {
		typ: ERROR
	}

	if (error.response) {
		switch (error.response) {
			case 'CANCEL':
				status.popis = 'Čítanie bolo manuálne zrušené.'
				break
			case 'NETWORK_ERROR':
			case 'xyzmoKioskCouldNotConnect':
			case 'initXyzmoKioskCouldNotConnect':
			case 'initXyzmoKioskNotStarted':
				status.popis = 'Nepodarilo sa pripojiť k WACOM zariadeniu.'
				break
			default:
				status.popis = 'Pri čítaní dokumentu nastala chyba.'
		}
	} else {
		status.popis = 'Pri čítaní dokumentu nastala chyba.'
	}

	return status
}

/**
 *
 * @param {Object} file
 */
export const importConfirmedFile = (file) => {
	return (dispatch) => {
		dispatch(
			readFileDone({
				id: get(file, 'id', undefined),
				name: file?.name,
				type: file?.contentType,
				dataAsBase64: get(file, 'dataAsBase64', undefined),
				signatureKey: file?.signatureKey,
				url: file?.url,
				wacom: false,
				akcia: file?.akcia,
				poradie: file?.poradie,
				poradieWacom: file?.poradieWacom
			})
		)
	}
}

export const readFile = (file) => {
	return async (dispatch) => {
		// NOTE: In case the user opens another document CP-3460
		if (checkPdfStateInterval) {
			clearInterval(checkPdfStateInterval)
			checkPdfStateInterval = null
		}

		// set source for canceling wacom requests
		source = axios.CancelToken.source()

		dispatch(readFileStart())

		try {
			await wacomAxios({
				url: '/showPdf?resetSign=1&showMirror=1',
				cancelToken: source.token,
				data: file?.data,
				method: 'POST',
				validateStatus(status) {
					return status < 400
				},
				params: { name: file?.name }
			})
			// after a sending pdf for signing check every 2 second state of signing process
			checkPdfStateInterval = setInterval(async () => {
				try {
					const pdfStateResult = await wacomAxios({
						url: '/pdfState',
						method: 'GET',
						cancelToken: source.token,
						// POZOR, unikatny param '_' je treba koli obideniu browser cache!!
						params: { name: file?.name, _: Date.now() }
					})

					if (pdfStateResult.data.error) {
						clearInterval(checkPdfStateInterval)
						const errorMsg = handleError({ response: 'prerusene' })
						dispatch(statusPushCreator(errorMsg))
						dispatch(readFileFail())
						return
					}

					// NOTE: In case the user opens another document CP-3460
					if (pdfStateResult.data.canceled || pdfStateResult.data === 'undefinedName') {
						clearInterval(checkPdfStateInterval)
						dispatch(readFileCancel())
						return
					}

					if (pdfStateResult.data.approved) {
						clearInterval(checkPdfStateInterval)
						dispatch(
							readFileDone({
								id: get(file, 'id', undefined),
								dataAsBase64: get(file, 'dataAsBase64', undefined),
								name: file?.name,
								url: file?.url,
								type: file?.contentType,
								wacom: true,
								signatureKey: file?.signatureKey,
								akcia: file?.akcia,
								poradie: file?.poradie,
								poradieWacom: file?.poradieWacom
							})
						)
						return
					}
				} catch (e) {
					clearInterval(checkPdfStateInterval)
					const errorMsg = handleError({ response: 'prerusene' })
					dispatch(statusPushCreator(errorMsg))
					dispatch(readFileFail())
				}
			}, 2000)
		} catch (e) {
			let err
			if (e.__CANCEL__) {
				err = 'CANCEL'
			} else if (e.message === 'Network Error') {
				err = 'NETWORK_ERROR'
			} else if (e && e.response) {
				err = e.response.data
			}

			if (checkPdfStateInterval) {
				clearInterval(checkPdfStateInterval)
			}
			const errorMsg = handleError({ response: err })
			dispatch(statusPushCreator(errorMsg))
			dispatch(readFileFail())
		}
	}
}
