import { indexOf } from 'lodash'

export const PRIHLASENIE = '/prihlasenie'
export const PRIHLASENIE_CHYBA = '/prihlasenie/chyba'
export const DOKONCENIE_PRIHLASENIA = '/dokoncenie-prihlasenia'

export const INDEX = '/'
export const PREHLAD = '/op/:cisloOP'

export const OP_DETAIL = '/op/:cisloOP/detail'
export const OP_UPRAVIT = '/op/:cisloOP/ukon-zmena-udajov-OP'

export const POHLADAVKY_ZOZNAM = '/op/:cisloOP/pohladavky'
export const POHLADAVKY_DETAIL = '/op/:cisloOP/pohladavky/:idPohladavky/detail'

export const ZOZNAM_SUHLASOV = '/op/:cisloOP/suhlasy'
export const ZMENA_SUHLASU = '/op/:cisloOP/zmena-suhlasu/:definiciaId'
export const HISTORIA_SUHLASU = '/op/:cisloOP/historia-suhlasu/:definiciaId'

export const ZMLUVNE_UCTY_ZOZNAM = '/op/:cisloOP/zu'
export const ZMLUVNE_UCTY_DETAIL = '/op/:cisloOP/zu/:cisloZU/detail'
export const ZMLUVNE_UCTY_UPRAVIT = '/op/:cisloOP/zu/:cisloZU/ukon-zmena-nastaveni-zu'

export const ZMLUVY_ZOZNAM = '/op/:cisloOP/zmluvy'

export const BANKOVE_UCTY_ZOZNAM = '/op/:cisloOP/bankove-ucty'
export const BANKOVE_UCTY_DETAIL = '/op/:cisloOP/bankove-ucty/:id/detail'

export const FAKTURY_ZOZNAM = '/op/:cisloOP/faktury'
export const FAKTURY_DETAIL = '/op/:cisloOP/faktury/:cisloFaktura/detail'

export const UPOMIENKY_ZOZNAM = '/op/:cisloOP/upomienky'
export const UPOMIENKY_DETAIL = '/op/:cisloOP/upomienky/:id/detail'

export const UCTOVNE_DOKLADY_DETAIL = '/op/:cisloOP/uctovne-doklady/:idPohladavky/detail'

export const PREPLATKY_ZOZNAM = '/op/:cisloOP/preplatky'
export const PREPLATKY_VYTVORIT = '/op/:cisloOP/preplatky/vytvorit'

export const BLOKOVANIE_UPOMINANIA_ZOZNAM = '/op/:cisloOP/blokovanie-upominania'
export const BLOKOVANIE_UPOMINANIA_VYTVORIT = '/op/:cisloOP/blokovanie-upominania/vytvorit'

export const ROZPISY_ZALOH_ZOZNAM = '/op/:cisloOP/rozpisy-zaloh'
export const ROZPISY_ZALOH_DETAIL = '/op/:cisloOP/rozpisy-zaloh/:cisloRozpisZaloh/detail'
export const ROZPISY_ZALOH_HISTORIA = '/op/:cisloOP/rozpisy-zaloh/:cisloRozpisZaloh/historia'
export const ROZPISY_ZALOH_UPRAVIT = '/op/:cisloOP/rozpisy-zaloh/:cisloRozpisZaloh/upravit'

export const PLANY_SPLATOK_ZOZNAM = '/op/:cisloOP/plany-splatok'
export const PLANY_SPLATOK_DETAIL = '/op/:cisloOP/plany-splatok/:id/detail'
export const PLANY_SPLATOK_VYTVORIT = '/op/:cisloOP/plany-splatok/vytvorit'

export const VZTAHY_ZOZNAM = '/op/:cisloOP/vztahy'
export const VZTAHY_DETAIL = '/op/:cisloOP/vztahy/:cisloSplnomocnenca/:typVztahu/detail'
export const VZTAHY_VYTVORIT = '/op/:cisloOP/vztahy/vytvorit'
export const VZTAHY_ZRUSIT = '/op/:cisloOP/vztahy/:cisloSplnomocnenca/:typVztahu/zrusit'

export const GENERIC_UKON = '/op/:cisloOP/genericky-ukon'

export const UKON_DETAIL = '/op/:cisloOP/ukony/:ukonCislo/detail'
export const DOKONCENIE_INTERAKCIE = '/op/:cisloOP/koniec-interakcie'

export const ANONYMNY_VSEOBECNY_UKON = '/anonymny-vseobecny-ukon'
export const ZALOZENIE_OP = '/zalozenie-op-ukon'

export const FORBIDDEN = '/403'

function insertIntoStirng(string, index, substring) {
	return [string.slice(0, index), substring, string.slice(index)].join('')
}

function removeIndexRangeFromString(string, from, to) {
	return [string.slice(0, from), string.slice(to)].join('')
}

export function setRouteParams() {
	if (!arguments.length) {
		return ''
	}

	let route = arguments[0]
	if (arguments.length === 1) {
		return route
	}

	for (let index = 1; index < arguments.length; index += 1) {
		const artument = encodeURIComponent(`${arguments[index]}`)

		const indexToReplace = indexOf(route, ':')
		if (indexToReplace != -1) {
			let endIndex = indexOf(route, '/', indexToReplace)
			if (endIndex == -1) {
				endIndex = route.length
			}

			const temp = removeIndexRangeFromString(route, indexToReplace, endIndex)
			route = insertIntoStirng(temp, indexToReplace, artument)
		}
	}
	return route
}

export function removeQueryParam(searchParams, paramName) {
	const urlParams = new URLSearchParams(searchParams)
	urlParams.delete(paramName)

	return urlParams.toString()
}
