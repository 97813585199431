import { find, get } from 'lodash'
import { BUSINESS_CHANNELS, UKON_VSTUP } from './enums'

export const getUkonVstupByBiznisKanal = (biznisKanalID, ukonVstupOptions) => {
	switch (biznisKanalID) {
		case BUSINESS_CHANNELS.ZSE_CENTRUM: {
			return find(ukonVstupOptions, (vstup) => get(vstup, 'id') == UKON_VSTUP.OSOBNE)
		}
		case BUSINESS_CHANNELS.ZAKAZNICKA_LINKA: {
			return find(ukonVstupOptions, (vstup) => get(vstup, 'id') == UKON_VSTUP.TELEFON)
		}
		case BUSINESS_CHANNELS.BACK_OFFICE: {
			return find(ukonVstupOptions, (vstup) => get(vstup, 'id') == UKON_VSTUP.EMAIL)
		}
		default: {
			return null
		}
	}
}

export const getUkonVstupIdByBiznisKanal = (biznisKanalID, ukonVstupOptions) => {
	const ukonVstup = getUkonVstupByBiznisKanal(biznisKanalID, ukonVstupOptions)

	return ukonVstup?.id
}
