import React from 'react'
import { withTranslation } from 'react-i18next'
import { debounce, get } from 'lodash'
import { compose } from 'redux'
import { FieldArray, getFormValues, reduxForm, Field } from 'redux-form'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

// atoms
import TextareaField from '../../../atoms/TextareaField'
import AcwSelector from '../../../atoms/AcwSelector'

// components
import GenericFieldArray from './GenericFieldArray'

// utils
import { FORMS, PROTECTION_INTERVAL_FOR_SUBMITTING, STAV_INDIKATORA } from '../../../utils/enums'
import validate from './validateForm'
import { getIsMop } from '../../../utils/obchodnyPartner'

export const GENERIC_FIELDS_KEY = 'ukony'

class GenericUkonForm extends React.Component {
	static propTypes = {
		ukony: PropTypes.shape(),
		obchodnyPartner: PropTypes.shape({
			data: PropTypes.shape()
		}),
		interakciaStartTime: PropTypes.any,
		currentTime: PropTypes.any,
		ukonOptions: PropTypes.array,
		isMOP: PropTypes.bool.isRequired,
		ukonyByInterakciaId: PropTypes.shape()
	}

	constructor(props) {
		super(props)

		this.state = {}
	}

	// NOTE: CP-3209
	showAcwSelector = () => {
		const { obchodnyPartnerDetail, digitalizacia, ukonyByInterakciaId, formValues } = this.props

		const ukonyInInteraction = get(ukonyByInterakciaId, 'data', [])
		const manualneUkony = get(formValues, 'ukony', [])

		if (ukonyInInteraction.length === 0 && manualneUkony.length === 0) {
			return false
		}

		const digitalizaciaDefinicie = get(digitalizacia, 'data.definicie', [])
		const umrtieDatum = get(obchodnyPartnerDetail, 'data.umrtieDatum', null)

		if (umrtieDatum !== null) {
			return false
		}
		return digitalizaciaDefinicie.some((definicia) => {
			return definicia?.stav === STAV_INDIKATORA.AMBER || definicia?.stav === STAV_INDIKATORA.RED
		})
	}

	validateAcw = (value) => {
		const { t, obchodnyPartnerDetail } = this.props
		const isAcwShown = this.showAcwSelector()

		const isMop = getIsMop(get(obchodnyPartnerDetail, 'data.skupinaOpravneni'))

		if (isMop) {
			return
		}

		if (isAcwShown && !value) {
			return t('containers:UkoncenieInterakcie.Vyberte dôvod')
		}

		return undefined
	}

	render() {
		const { t, interakciaStartTime, currentTime, ukonOptions, isMOP, formValues, cesCiselniky } = this.props

		const acwOptions = get(cesCiselniky, 'dovodNedigitalizacia', [])

		return (
			<>
				<table className='content-table bordered padded top-aligned'>
					<thead>
						<tr>
							<th style={{ width: '280px' }}>{t('containers:UkoncenieInterakcie.Čas úkonu')}</th>
							<th>{t('containers:UkoncenieInterakcie.Typ úkonu')}</th>
							<th style={{ width: '40px' }} />
						</tr>
					</thead>
					<tbody>
						<FieldArray
							name={GENERIC_FIELDS_KEY}
							props={{ interakciaStartTime, currentTime, ukonOptions, isMOP, formValues }}
							component={GenericFieldArray}
						/>
					</tbody>
				</table>
				{this.showAcwSelector() && (
					<div style={{ margin: '32px 0' }}>
						<strong style={{ display: 'block', marginBottom: '10px' }}>
							{t('containers:UkoncenieInterakcie.Uveďte dôvod neaktualizácie údajov/nedigitalizácie zákazníka')}
						</strong>
						<Field name='acwValue' acwOptions={acwOptions} component={AcwSelector} validate={this.validateAcw} />
					</div>
				)}
				<Field
					name='poznamka'
					component={TextareaField}
					showLabel
					label={t('containers:UkoncenieInterakcie.Poznámka')}
					placeholder={t('containers:UkoncenieInterakcie.Tu napíšte ľubovoľnú poznámku k realizovanej interakcii')}
				/>
			</>
		)
	}
}

const form = reduxForm({
	form: FORMS.GENERIC_UKON_UKONCIT_INTERAKCIU,
	destroyOnUnmount: false,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	onChange: debounce((_values, _dispatch, { submit, anyTouched }) => {
		if (anyTouched) {
			submit()
		}
	}, PROTECTION_INTERVAL_FOR_SUBMITTING),
	validate
})(GenericUkonForm)

const mapStateToProps = (state) => ({
	obchodnyPartnerDetail: state.obchodnyPartner.detail,
	formValues: getFormValues(FORMS.GENERIC_UKON_UKONCIT_INTERAKCIU)(state),
	cesCiselniky: state.cesCiselniky.data,
	ukonyByInterakciaId: state.ukony.ukonyByInterakciaId
})

const mapDispatchToProps = (dispatch) => ({
	dispatch
})

export default compose(withTranslation('components'), connect(mapStateToProps, mapDispatchToProps))(form)
