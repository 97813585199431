import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { get, isEmpty, includes, find, head, map, filter } from 'lodash'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'
import { getFormValues } from 'redux-form'

import Modal from './Modal'

import Select from '../../atoms/BasicSelect'
import crossIcon from '../../resources/img/icons/cross-black.svg'
import { openDataUriWindow } from '../../utils/files'
import { containsDiacritics, isEmail } from '../../utils/email'
import Input from '../../atoms/Input'
import AddressCustomField from '../../atoms/AddressField/AddressCustomField'
import { FORMS, NOTIFICATION_TYPES, BUSINESS_CHANNELS } from '../../utils/enums'

import { filterUniqueAddresses, formatAddress } from '../../utils/address'

const CUSTOM = {
	EMAIL: 'EMAIL',
	ADDRESS: -1
}

class VyberZaslaniaDokumentu extends React.Component {
	static propTypes = {
		auth: PropTypes.shape().isRequired,
		modalTitle: PropTypes.string,
		onCloseButton: PropTypes.func.isRequired,
		onSubmit: PropTypes.func.isRequired,
		partnerStore: PropTypes.shape({
			data: PropTypes.shape().isRequired
		}).isRequired,
		t: PropTypes.func.isRequired,
		documentUrl: PropTypes.string,
		fileName: PropTypes.string,
		signedDocuments: PropTypes.array,
		defaultNotificationType: PropTypes.string,
		notificationTypes: PropTypes.array,
		addresses: PropTypes.array,
		formValues: PropTypes.shape(),
		formGenericValues: PropTypes.shape(),
		formUpravaZUValues: PropTypes.shape(),
		eFakturaEmaily: PropTypes.array,
		adresy: PropTypes.array,
		emailAddresses: PropTypes.array
	}

	constructor(props) {
		super(props)

		this.emailInputRef = React.createRef()

		this.state = {
			odoslanieTyp: null,
			selectedAddress: null,
			selectedEmail: null,
			customEmail: {
				value: '',
				error: null
			},
			customAddress: null,
			emails: [],
			addresses: []
		}
	}

	componentDidMount() {
		const {
			auth,
			defaultNotificationType,
			notificationTypes,
			partnerStore,
			eFakturaEmaily,
			t,
			formValues,
			formGenericValues,
			formUpravaZUValues,
			adresy,
			emailAddresses
		} = this.props

		const obchodnyPartner = get(partnerStore, 'data')

		const opEmail = get(obchodnyPartner, 'kontaktnyEmail')
		const formOpEmail = get(formValues, 'kontaktnyEmail')
		const formEFaktury = get(formValues, 'eFaktury')
		const formGenericEFakturaEmaily = get(formGenericValues, 'data.zmluvnyUcet.eFakturaEmaily')
		const formUpravaZUEFakturaEmaily = get(formUpravaZUValues, 'eFakturaEmaily')

		const emailOP = opEmail ? [{ value: opEmail, label: opEmail }] : []
		const emailForm = formOpEmail && formOpEmail !== opEmail ? [{ value: formOpEmail, label: formOpEmail }] : []
		const emailEFaktura = Array.isArray(eFakturaEmaily)
			? map(
					filter(eFakturaEmaily, (eFaktura) => {
						const email = get(eFaktura, 'email')
						return (!formOpEmail || formOpEmail != email) && (!opEmail || opEmail != email)
					}),
					(eFaktura) => ({
						value: get(eFaktura, 'email'),
						label: get(eFaktura, 'email')
					})
			  )
			: []

		const formEmailEFaktura = Array.isArray(formEFaktury)
			? map(formEFaktury, (eFaktura) => {
					return {
						value: get(eFaktura, 'email'),
						label: get(eFaktura, 'email')
					}
			  })
			: []

		const genericFormEFakturaEmaily = Array.isArray(formGenericEFakturaEmaily)
			? map(formGenericEFakturaEmaily, (eFakturaEmail) => {
					return {
						value: get(eFakturaEmail, 'email'),
						label: get(eFakturaEmail, 'email')
					}
			  })
			: []

		const upravaZUEFakturaEmaily = Array.isArray(formUpravaZUEFakturaEmaily)
			? map(formUpravaZUEFakturaEmaily, (eFakturaEmail) => {
					return {
						value: get(eFakturaEmail, 'email'),
						label: get(eFakturaEmail, 'email')
					}
			  })
			: []

		const inyEmail = {
			value: CUSTOM.EMAIL,
			label: t('components:VzdModal.Zvoliť iný email')
		}

		const addresses = adresy || get(partnerStore, 'data.adresy', [])
		const selectedAddress = this.getSelectedAddress(addresses)

		let emails = []
		if (emailAddresses) {
			emails = [...emailAddresses, inyEmail]
		} else {
			emails = [...emailForm, ...emailOP, ...emailEFaktura, ...formEmailEFaktura, ...genericFormEFakturaEmaily, ...upravaZUEFakturaEmaily, inyEmail]
		}

		let odoslanieTyp = null
		if (defaultNotificationType) {
			odoslanieTyp = defaultNotificationType
		} else {
			if (includes(notificationTypes, NOTIFICATION_TYPES.EMAIL)) {
				odoslanieTyp = NOTIFICATION_TYPES.EMAIL
			} else if (includes(notificationTypes, NOTIFICATION_TYPES.ADDRESS)) {
				odoslanieTyp = NOTIFICATION_TYPES.ADDRESS
			} else if (includes(notificationTypes, NOTIFICATION_TYPES.PRINTER)) {
				odoslanieTyp = NOTIFICATION_TYPES.PRINTER
			}
			// Pre Zakaznicku linku v pripade, ze nie je povinnost poslat notifikaciu defaultne oznacime moznost "Bez notifikacie"
			if (
				get(auth, 'businessChannel.actual.id') == BUSINESS_CHANNELS.ZAKAZNICKA_LINKA &&
				includes(notificationTypes, NOTIFICATION_TYPES.VOID_NOTIFICATION)
			) {
				odoslanieTyp = NOTIFICATION_TYPES.VOID_NOTIFICATION
			}
		}

		// NOTE: select default email
		let selectedEmail
		if (odoslanieTyp == NOTIFICATION_TYPES.EMAIL) {
			if (!isEmpty(emailAddresses)) {
				selectedEmail = head(emailAddresses)
			} else if (!isEmpty(emailForm)) {
				selectedEmail = emailForm[0]
			} else if (!isEmpty(emailOP)) {
				selectedEmail = emailOP[0]
			} else if (!isEmpty(emailEFaktura)) {
				selectedEmail = emailEFaktura[0]
			} else {
				selectedEmail = inyEmail
			}
		}

		this.setState({
			odoslanieTyp,
			emails,
			selectedEmail,
			selectedAddress,
			addresses
		})
	}

	getSelectedAddress = (addresses) => {
		const { partnerStore } = this.props

		// check if OP has adresa korespondencna
		const adresaKorespondencna = find(addresses, {
			id: get(partnerStore, 'data.adresaKorespondencna.id')
		})

		let selectedAddress = null

		if (adresaKorespondencna) {
			// set adresa korespondencna as default selected address
			selectedAddress = {
				addressDTO: adresaKorespondencna,
				value: adresaKorespondencna.id,
				label: formatAddress(adresaKorespondencna)
			}
		} else {
			// if OP does not have adresa korespondenca check if has adresa zakaznika
			const adresaZakaznika = find(addresses, { id: get(partnerStore, 'data.adresaZakaznika.id') })
			if (adresaZakaznika) {
				selectedAddress = {
					addressDTO: adresaZakaznika,
					value: adresaZakaznika.id,
					label: formatAddress(adresaZakaznika)
				}
			} else {
				// if OP does not have adresa korespondenca and adresa zakaznika check if has any adress and pick the first one otherwise set null
				selectedAddress = !isEmpty(addresses) ? { addressDTO: addresses[0], value: addresses[0].id, label: formatAddress(addresses[0]) } : null
			}
		}

		return selectedAddress
	}

	handleSubmit = () => {
		const { t } = this.props
		const { selectedAddress, odoslanieTyp, selectedEmail, customEmail, customAddress } = this.state

		let email = null
		let address = null

		if (get(selectedEmail, 'value') === CUSTOM.EMAIL) {
			if (!customEmail.value) {
				return this.setState({
					customEmail: {
						error: t('components:VzdModal.Emailová adresa je povinná')
					}
				})
			}
			if (!isEmail(customEmail.value)) {
				return this.setState({
					customEmail: {
						error: t('components:VzdModal.Neplatná emailová adresa')
					}
				})
			}
			if (containsDiacritics(customEmail.value)) {
				return this.setState({
					customEmail: {
						error: t('components:VzdModal.Emailová adresa nesmie obsahovať diakritiku')
					}
				})
			}
			email = customEmail.value
		} else {
			email = selectedEmail ? selectedEmail.value : null
		}

		if (get(selectedAddress, 'value') === CUSTOM.ADDRESS) {
			if (!get(customAddress, 'value') || !isEmpty(get(customAddress, 'errors'))) {
				return
			}
			address = customAddress.value
		} else {
			address = selectedAddress ? selectedAddress.addressDTO : null
		}
		this.props.onSubmit({
			email,
			address,
			typ: odoslanieTyp
		})
	}

	handleAddressChange = (adresaOption) => {
		this.setState({
			selectedAddress: adresaOption,
			selectedEmail: null
		})
	}

	handleEmailChange = (newValue) => {
		this.setState({
			selectedEmail: newValue,
			selectedAddress: null
		})
	}

	handleCustomEmail = (e) => {
		this.setState({
			customEmail: {
				value: e.currentTarget.value
			},
			selectedAddress: null
		})
	}

	changeType = (type) => {
		const { addresses } = this.state

		if (type == NOTIFICATION_TYPES.ADDRESS) {
			let selectedAddress = this.getSelectedAddress(addresses)

			this.setState({
				odoslanieTyp: type,
				selectedEmail: null,
				customEmail: {
					value: '',
					error: null
				},
				selectedAddress
			})
		}

		if (type == NOTIFICATION_TYPES.EMAIL) {
			this.setState({
				odoslanieTyp: type,
				selectedAddress: null,
				customAddress: null,
				selectedEmail: this.state.emails.length
					? this.state.emails[0]
					: {
							value: CUSTOM.EMAIL,
							label: this.props.t('components:VzdModal.Zvoliť iný email')
					  }
			})
		}

		if (type == NOTIFICATION_TYPES.PRINTER || type == NOTIFICATION_TYPES.VOID_NOTIFICATION) {
			this.setState({
				odoslanieTyp: type,
				selectedAddress: null,
				customAddress: null,
				customEmail: {
					value: '',
					error: null
				},
				selectedEmail: null
			})
		}
	}

	handleCreateEmail = (inputValue) => {
		const { emails } = this.state

		const newOption = {
			value: inputValue,
			label: inputValue
		}
		this.setState({
			emails: [...emails, newOption],
			selectedEmail: newOption
		})
	}

	handleNewAddress = (address) => {
		this.setState({
			customAddress: {
				value: address.editAddress,
				errors: address.errors
			}
		})
	}

	componentDidUpdate(prevProps, prevState) {
		const { selectedEmail } = this.state

		if (get(prevState, 'selectedEmail.value') !== get(selectedEmail, 'value') && get(selectedEmail, 'value') === CUSTOM.EMAIL) {
			if (get(this, 'emailInputRef.current')) {
				this.emailInputRef.current.focus()
			}
		}
	}

	render() {
		const { modalTitle, t, documentUrl, fileName, signedDocuments, notificationTypes, addresses, adresy } = this.props
		const { selectedAddress, selectedEmail, emails, odoslanieTyp, customEmail, customAddress } = this.state

		let content = null

		if (includes(notificationTypes, NOTIFICATION_TYPES.ADDRESS) && odoslanieTyp == NOTIFICATION_TYPES.ADDRESS) {
			content = (
				<AddressCustomField
					label={t('components:VzdModal.Zvoľte adresu')}
					addresses={filterUniqueAddresses(adresy || addresses)}
					value={selectedAddress}
					isClearable={false}
					newAddress={get(selectedAddress, 'value') === CUSTOM.ADDRESS}
					onChange={(adresaOption) => this.setState({ selectedAddress: adresaOption })}
					handleNewAddress={this.handleNewAddress}
				/>
			)
		} else if (includes(notificationTypes, NOTIFICATION_TYPES.EMAIL) && odoslanieTyp == NOTIFICATION_TYPES.EMAIL) {
			content = (
				<>
					{emails.length > 0 && (
						<Select
							label={t('components:VzdModal.Zvoľte email')}
							onChange={this.handleEmailChange}
							options={emails}
							value={selectedEmail}
							isClearable={false}
							isSearchable={false}
							onCreateOption={this.handleCreateEmail}
							placeholder={t('translation:Common.Prosím vyberte emailovú adresu')}
							noOptionsMessage={() =>
								`${t('components:VzdModal.Filtru nevyhovuje žiadna emailová adresa')}. ${t(
									'components:VzdModal.Prosím napíšte validnú emailovú adresu pre vytvorenie'
								)}`
							}
						/>
					)}
					{get(selectedEmail, 'value') === CUSTOM.EMAIL && (
						<Input
							refs={this.emailInputRef}
							onChange={this.handleCustomEmail}
							label={t('components:VzdModal.Zadajte emailovú adresu')}
							placeholder='Email'
							error={customEmail.error}
							showLabel
							required
						/>
					)}
				</>
			)
		} else if (includes(notificationTypes, NOTIFICATION_TYPES.PRINTER) && odoslanieTyp == NOTIFICATION_TYPES.PRINTER && fileName) {
			content = (
				<a
					className='file file-name'
					onClick={(e) => {
						e.preventDefault()
						openDataUriWindow(documentUrl, fileName)
					}}
					data-type='general'
					style={{ cursor: 'pointer' }}
				>
					{fileName}
				</a>
			)
		} else if (includes(notificationTypes, NOTIFICATION_TYPES.PRINTER) && odoslanieTyp == NOTIFICATION_TYPES.PRINTER && signedDocuments) {
			content = (
				<div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
					{signedDocuments.map((signedDocument, index) => {
						return (
							<a
								key={`${signedDocument.fileName}-${index}`}
								className='file file-name'
								onClick={(e) => {
									e.preventDefault()
									openDataUriWindow(signedDocument?.id || signedDocument?.documentUrl, signedDocument.fileName)
								}}
								data-type='general'
								style={{ cursor: 'pointer' }}
							>
								{signedDocument.fileName}
							</a>
						)
					})}
				</div>
			)
		} else if (includes(notificationTypes, NOTIFICATION_TYPES.VOID_NOTIFICATION) && odoslanieTyp == NOTIFICATION_TYPES.VOID_NOTIFICATION) {
			content = <span>{t('translation:Common.Notifikácia nebude poslaná')}</span>
		}

		return (
			<Modal shown size='s'>
				{modalTitle && (
					<div className='modal-header'>
						<h3>{modalTitle}</h3>
						<div className='close' onClick={() => this.props.onCloseButton()}>
							<img src={crossIcon} width='25' />
						</div>
					</div>
				)}
				<div className='modal-content'>
					<div className='row' style={{ paddingBottom: '20px' }}>
						{includes(notificationTypes, NOTIFICATION_TYPES.ADDRESS) && (
							<div className='col-6'>
								<button
									className='button'
									type='button'
									onClick={() => this.changeType(NOTIFICATION_TYPES.ADDRESS)}
									data-color='blue'
									data-type={odoslanieTyp != NOTIFICATION_TYPES.ADDRESS ? 'outline' : ''}
									style={{ width: '100%', paddingLeft: '10px', paddingRight: '10px' }}
								>
									{t('components:VzdModal.Poštou')}
								</button>
							</div>
						)}
						{includes(notificationTypes, NOTIFICATION_TYPES.EMAIL) && (
							<div className='col-6'>
								<button
									className='button'
									type='button'
									onClick={() => this.changeType(NOTIFICATION_TYPES.EMAIL)}
									data-color='blue'
									data-type={odoslanieTyp != NOTIFICATION_TYPES.EMAIL ? 'outline' : ''}
									style={{ width: '100%', paddingLeft: '10px', paddingRight: '10px' }}
								>
									{t('components:VzdModal.Emailom')}
								</button>
							</div>
						)}
						{includes(notificationTypes, NOTIFICATION_TYPES.PRINTER) && (
							<div className='col-6'>
								<button
									className='button'
									type='button'
									onClick={() => this.changeType(NOTIFICATION_TYPES.PRINTER)}
									data-color='blue'
									data-type={odoslanieTyp != NOTIFICATION_TYPES.PRINTER ? 'outline' : ''}
									style={{ width: '100%', paddingLeft: '10px', paddingRight: '10px' }}
								>
									{t('components:VzdModal.Tlač')}
								</button>
							</div>
						)}
						{includes(notificationTypes, NOTIFICATION_TYPES.VOID_NOTIFICATION) && (
							<div className='col-6'>
								<button
									className='button'
									type='button'
									onClick={() => this.changeType(NOTIFICATION_TYPES.VOID_NOTIFICATION)}
									data-color='blue'
									data-type={odoslanieTyp != NOTIFICATION_TYPES.VOID_NOTIFICATION ? 'outline' : ''}
									style={{ width: '100%', paddingLeft: '10px', paddingRight: '10px' }}
								>
									{t('components:VzdModal.Bez notifikácie')}
								</button>
							</div>
						)}
					</div>
					<div className='row'>
						<div className='col-12'>{content}</div>
					</div>
				</div>
				<div className='modal-footer clearfix'>
					<button
						type='button'
						className='button'
						onClick={this.handleSubmit}
						data-color='blue'
						disabled={
							(odoslanieTyp == NOTIFICATION_TYPES.EMAIL && !selectedEmail) ||
							(odoslanieTyp == NOTIFICATION_TYPES.ADDRESS && !get(customAddress, 'value') && !selectedAddress) ||
							(get(selectedAddress, 'value') == CUSTOM.ADDRESS && !isEmpty(get(customAddress, 'errors')))
						}
						style={{ width: '100%', marginLeft: 0 }}
					>
						{t('components:VzdModal.Pokračovať')}
					</button>
				</div>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	auth: get(state, 'auth'),
	addresses: get(state, 'formAddresses.data'),
	partnerStore: get(state, 'obchodnyPartner.detail'),
	formValues: getFormValues(FORMS.OBCHODNY_PARTNER_EDITACIA)(state),
	formGenericValues: getFormValues(FORMS.GENERIC_UKON)(state),
	formUpravaZUValues: getFormValues(FORMS.UPRAVA_ZMLUVNEHO_UCTU)(state)
})

export default compose(withTranslation('components'), connect(mapStateToProps))(VyberZaslaniaDokumentu)
