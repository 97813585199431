import React from 'react'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { filter, forEach, get, head, isEqual, map, omit, set, some } from 'lodash'
import { change } from 'redux-form'
import dayjs from 'dayjs'

// components
import { getGenericUkonConfig, COLUMNS_COUNT, EDIT_MODE, FIELD_PATH } from '../../../containers/GenericUkon/genericUkonConfig'
import UndefinedField from '../fields/UndefinedField'
import { UKON_CATEGORY_ONE_KEY, UKON_TIME_RANGE_KEY } from './GenericFieldArray'
import ElementLoading from '../../ElementLoading'
import { GENERIC_FIELDS_KEY } from './GenericUkonForm'

// utils
import { FORMS } from '../../../utils/enums'
import { postReq } from '../../../utils/request'

class GenericFields extends React.Component {
	static propTypes = {
		parentIndex: PropTypes.number.isRequired,
		parentFieldName: PropTypes.string.isRequired,
		selectedUkon: PropTypes.shape().isRequired,
		ponuka: PropTypes.shape(),
		interakcia: PropTypes.shape(),
		auth: PropTypes.shape(),
		tracking: PropTypes.shape(),
		formValues: PropTypes.shape(),
		change: PropTypes.shape()
	}

	constructor(props) {
		super(props)

		this.state = {
			isLoading: false,
			selectedPonuka: null,
			divHeight: null,
			attributes: {
				supportedAttributes: [],
				unsupportedAttributes: [],
				supportedEmptyLookupAttributes: []
			}
		}
	}

	_divElement = null

	prepareAttributes = (attributes, ukon) => {
		const genericUkonConfig = getGenericUkonConfig(ukon?.typ)
		const fieldsConfig = genericUkonConfig.fields

		let supportedAttributes = map(
			filter(attributes, (attribute) => {
				const config = fieldsConfig[get(attribute, 'typ')]
				return config && !get(config, 'readonly')
			}),
			(attribute) => ({
				...attribute,
				...fieldsConfig[get(attribute, 'typ')]
			})
		)
		const unsupportedAttributes = map(
			filter(attributes, (attribute) => {
				return !fieldsConfig[get(attribute, 'typ')]
			})
		)
		const supportedEmptyLookupAttributes = filter(supportedAttributes, (attribute) => {
			const attributeData = get(ukon, get(attribute, 'cesta'))
			return get(attribute, 'cesta') && get(attribute, 'vstupny') && (attributeData == null || attributeData == undefined)
		})

		// CP-2895 - Kategórie 2 a 3 sa mapujú len vizuálne na kategóriu 1 a 2
		supportedAttributes = supportedAttributes?.map((attribute) => {
			if (get(attribute, 'typ') === 'AttrUkonKategoria2DTO') {
				return {
					...attribute,
					label: 'Kategória 1'
				}
			}

			if (get(attribute, 'typ') === 'AttrUkonKategoria3DTO') {
				return {
					...attribute,
					label: 'Kategória 2'
				}
			}

			return attribute
		})

		return {
			supportedAttributes,
			unsupportedAttributes,
			supportedEmptyLookupAttributes
		}
	}

	schvalovacieKriteriaHandler = (schvalovacieKriteriaNew) => {
		const { schvalovacieKriteria } = this.state

		if (!isEqual(schvalovacieKriteria, schvalovacieKriteriaNew)) {
			this.setState({
				schvalovacieKriteria: schvalovacieKriteriaNew
			})
		}
	}

	formatDataForUkon = (values) => {
		const { interakcia, auth } = this.props
		const { attributes, selectedPonuka } = this.state

		const startTime = get(values, `${UKON_TIME_RANGE_KEY}.zacatyOd`)

		let data = {
			...omit(values, ['INIT_TIMESTAMP']),
			data: {
				...get(values, 'data', {})
			},
			trvanie: dayjs().diff(dayjs(startTime), 'millisecond'),
			zacatyOd: dayjs(startTime).toISOString(),
			typ: get(selectedPonuka, 'ukon.typ'),
			opCislo: get(interakcia, 'detail.data.opCislo'),
			interakciaId: get(interakcia, 'detail.data.id'),
			riesitel: get(auth, 'user.id'),
			kanal: get(auth, 'businessChannel.actual'),
			dokumenty: undefined
		}

		forEach(attributes, (attribute) => {
			if (get(attribute, 'format')) {
				const formattedValue = attribute.format(this.props, get(values, get(attribute, 'cesta')))
				set(data, get(attribute, 'cesta'), formattedValue)
			}
		})

		return data
	}

	prepareDraftRecalc = async () => {
		const { dispatch, formValues, parentIndex } = this.props
		const { selectedPonuka } = this.state

		// key of form field in fieldArray which is represents key of rendered "sub form"
		const fieldKey = `${GENERIC_FIELDS_KEY}[${parentIndex}]`
		// "sub form" actual values
		const fieldValues = get(formValues, fieldKey)

		this.setState({
			...this.state,
			isLoading: true
		})

		try {
			const body = this.formatDataForUkon(fieldValues)

			const res = await postReq(`/api/v2/ukony/draft-prepare`, null, body)
			const ponukaRecalc = get(res, 'response.content')

			const ukon = get(ponukaRecalc, 'ukon', {})

			const { supportedAttributes, unsupportedAttributes, supportedEmptyLookupAttributes } = this.prepareAttributes(
				get(ponukaRecalc, 'atributy', []),
				ukon
			)

			// update "sub form" field values
			forEach(supportedAttributes, (attribute) => {
				if (get(attribute, 'cesta') === FIELD_PATH.DOKUMENTY) return

				if (!get(attribute, 'cesta') || get(ukon, get(attribute, 'cesta')) === undefined) return

				const attributeKey = `${fieldKey}.${get(attribute, 'cesta')}`
				const attributeValue = get(ukon, get(attribute, 'cesta'))
				// check if recalc change previous "sub form" field value
				if (!isEqual(fieldValues?.[get(attribute, 'cesta')], attributeValue)) {
					dispatch(change(FORMS.GENERIC_UKON_UKONCIT_INTERAKCIU, attributeKey, attributeValue, null))
				}
			})

			// NOTE: adding ukon id
			dispatch(change(FORMS.GENERIC_UKON_UKONCIT_INTERAKCIU, `${fieldKey}.id`, get(ukon, 'id')))

			this.setState({
				selectedPonuka: {
					...selectedPonuka,
					ukon: { ...get(ponukaRecalc, 'ukon'), id: get(ponukaRecalc, 'ukon.id') },
					atributy: get(ponukaRecalc, 'atributy')
				},
				attributes: {
					supportedAttributes,
					unsupportedAttributes,
					supportedEmptyLookupAttributes
				},
				isLoading: false
			})
		} catch (e) {
			// eslint-disable-next-line no-console
			console.error(e)
			this.setState({
				...this.state,
				isLoading: false
			})
		}
	}

	async componentDidUpdate(prevProps) {
		const { dispatch, parentIndex, ponuka, selectedUkon, formValues } = this.props
		const { attributes, divHeight } = this.state
		let height = null

		// NOTE: get actual height of "sub form" box
		if (this._divElement?.clientHeight && this._divElement?.clientHeight > 0) {
			height = this._divElement.clientHeight
		}

		// NOTE: key of form field in fieldArray which is represents key of rendered "sub form"
		const fieldKey = `${GENERIC_FIELDS_KEY}[${parentIndex}]`
		// NOTE: "sub form" actual values
		const fieldValues = get(formValues, fieldKey)
		// NOTE: "sub form" previous values
		const prevFieldValues = get(prevProps?.formValues, fieldKey)

		// NOTE: check if ukonKategoria1 in sub form is changed
		if (!isEqual(fieldValues?.[UKON_CATEGORY_ONE_KEY], prevFieldValues?.[UKON_CATEGORY_ONE_KEY])) {
			const filteredPonuka = head(ponuka.obchodnyPartner.data?.filter((option) => option.nazov === selectedUkon))
			if (filteredPonuka) {
				const ukon = get(filteredPonuka, 'ukon', {})
				const attributes = this.prepareAttributes(filteredPonuka?.atributy, ukon)

				this.setState({
					...this.state,
					attributes,
					selectedPonuka: filteredPonuka,
					divHeight: null
				})

				// NOTE: init "sub form" field values
				dispatch(change(FORMS.GENERIC_UKON_UKONCIT_INTERAKCIU, fieldKey, { ...fieldValues, ...ukon }))
			}
		} else if (height && height !== divHeight) {
			// NOTE: not same height and height is not null, update height of "sub form" box
			this.setState({
				...this.state,
				divHeight: height
			})
		}

		const supportedAtributes = get(attributes, 'supportedAttributes')

		if (prevFieldValues && !isEqual(fieldValues, prevFieldValues)) {
			const changedRecalcAttribute = some(supportedAtributes, (attribute) => {
				if (!get(attribute, 'cesta')) {
					return
				}
				if (isEqual(get(fieldValues, get(attribute, 'cesta')), get(prevFieldValues, get(attribute, 'cesta')))) {
					return
				}
				if (get(attribute, 'recalc') && !get(attribute, 'vstupny')) {
					return attribute
				}
			})

			if (changedRecalcAttribute) {
				this.prepareDraftRecalc()
			}
		}
	}

	render() {
		const { parentIndex, parentFieldName, formValues } = this.props
		const { attributes, isLoading, divHeight } = this.state

		if (isLoading) {
			return (
				<div style={{ minHeight: divHeight }}>
					<ElementLoading />
				</div>
			)
		}

		const editMode = EDIT_MODE.EDIT

		const renderSupportedAttributes = map(attributes.supportedAttributes, (attribute, index) => {
			const fieldComponent = get(attribute, 'fieldComponent')
			const fieldPath = get(attribute, 'cesta')
			const fieldName = `${parentFieldName}.${get(attribute, 'cesta')}`

			return React.createElement(fieldComponent, {
				key: `generic-form-field-${index}`,
				name: fieldName,
				form: FORMS.GENERIC_UKON_UKONCIT_INTERAKCIU,
				field: fieldName || null,
				parentFieldName,
				editMode,
				columnsCount: COLUMNS_COUNT.TWO,
				value: fieldPath ? get(formValues, `${GENERIC_FIELDS_KEY}[${parentIndex}].[${fieldPath}]`) : null,
				formValues,
				scenarioOption: get(confirm, 'scenarioOption'),
				...attribute
			})
		})

		const renderUnsupportedFields = map(attributes.unsupportedAttributes, (attribute, index) => {
			const fieldPath = get(attribute, 'cesta')
			const fieldName = `${parentFieldName}.${get(attribute, 'cesta')}`

			return React.createElement(UndefinedField, {
				key: `generic-form-unsupported-field-${index}`,
				field: fieldPath || null,
				name: fieldName,
				editMode,
				columnsCount: COLUMNS_COUNT.THREE,
				value: fieldPath ? get(formValues, `${GENERIC_FIELDS_KEY}[${parentIndex}].[${fieldPath}]`) : null,
				...attribute
			})
		})

		return (
			<div
				style={{ minHeight: divHeight }}
				ref={(divElement) => {
					this._divElement = divElement
				}}
			>
				{renderSupportedAttributes}
				{renderUnsupportedFields}
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	auth: state.auth,
	ponuka: state.ponuka,
	tracking: state.tracking,
	interakcia: state.interakcie
})

const mapDispatchToProps = (dispatch) => ({
	dispatch
})

export default compose(withTranslation('components'), connect(mapStateToProps, mapDispatchToProps))(GenericFields)
